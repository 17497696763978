import {
    LOAD_MY_CLUB,
    LOADED_MY_CLUB,
    LOADED_MY_CLUB_FAIL,
    LOAD_VALIDATORS,
    LOAD_VALIDATORS_SUCCESS,
    LOAD_VALIDATORS_FAIL
} from './types';
import axios from 'axios';
import  getAxiosConfig  from '../utils/axiosConfig';
import { getAuthUserId } from '../reducers/authSelectors';


export const loadMyClub = () => async (dispatch, getState) => {
    if (localStorage.getItem('access')) {
        try {
            dispatch({
                type: LOAD_MY_CLUB
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/club/my-club/`, getAxiosConfig());

            const authUserId = getAuthUserId(getState());
            const dispatchPayload = {
                        clubData: res.data,
                                authUserId,
                            };

            dispatch({
                type: LOADED_MY_CLUB,
                payload: dispatchPayload,
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                dispatch({
                    type: LOADED_MY_CLUB_FAIL,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: LOADED_MY_CLUB_FAIL,
                    payload: 'An unknown error occurred'
                });
            }
        }
    }
}


export const loadMyClubValidators = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        try {
            dispatch({
                type: LOAD_VALIDATORS
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/club/owner/validators/`, getAxiosConfig());
            dispatch({
                type: LOAD_VALIDATORS_SUCCESS,
                payload: res.data,
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                dispatch({
                    type: LOAD_VALIDATORS_FAIL,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: LOAD_VALIDATORS_FAIL,
                    payload: 'An unknown error occurred'
                });
            }
        }
    }
}