import {
    LOAD_MY_CATCH_MAP_DATA,
    LOADED_MY_CATCH_MAP_DATA,
    LOADED_MY_CATCH_MAP_DATA_FAIL,
    EVENT_NOT_ENDED,
} from '../actions/types';

const initialState = {
    loading: false,
    mapdata: null,
    error: null,
    errorMessage: null
};

export default function myMapCatchDataReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MY_CATCH_MAP_DATA:
            return {
                ...state,
                loading: true,
            };

        case LOADED_MY_CATCH_MAP_DATA:
            return {
                ...state,
                loading: false,
                mapdata: action.payload,
            };
        case LOADED_MY_CATCH_MAP_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || 'An unknown error occurred'
        };
            case EVENT_NOT_ENDED:
                return {
                ...state,
                loading: false,
                error: action.payload,
    };
        default:
            return state;
    }
};