import {
    MARK_NOTIFICATION_AS_READ,
    MARK_NOTIFICATION_AS_READ_FAIL,
    LOAD_MY_NOTIFICATIONS,
    LOADED_MY_NOTIFICATIONS,
    LOADED_MY_NOTIFICATIONS_FAIL
} from './types';
import axios from 'axios';
import  getAxiosConfig  from '../utils/axiosConfig';

export const loadMyNotifications = () => async (dispatch, getState) => {
    if (localStorage.getItem('access')) {
        try {
            dispatch({
                type: LOAD_MY_NOTIFICATIONS
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/notifications/`, getAxiosConfig());

            const dispatchPayload = {
                        notifications: res.data.notifications,
                            };


            dispatch({
                type: LOADED_MY_NOTIFICATIONS,
                payload: dispatchPayload,
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                dispatch({
                    type: LOADED_MY_NOTIFICATIONS_FAIL,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: LOADED_MY_NOTIFICATIONS_FAIL,
                    payload: 'An unknown error occurred'
                });
            }
        }
    }
}

export const markNotificationAsRead = (notificationId) => async (dispatch) => {

    try {
        const config = getAxiosConfig();
        await axios.patch(`${process.env.REACT_APP_API_URL}/v1/notifications/${notificationId}/`, { is_read: true }, config);

        dispatch({
            type: MARK_NOTIFICATION_AS_READ,
            payload: { notificationId, is_read: true },
        });
    } catch (error) {
        console.error('Error marking notification as read:', error);
        dispatch({
            type: MARK_NOTIFICATION_AS_READ_FAIL,
            payload: error.response?.data?.error || 'An error occurred while marking the notification as read',
        });
    }
};