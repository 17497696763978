import {
    LOAD_EVENTS,
    LOADED_EVENTS,
    LOADED_EVENTS_FAIL,
    LOAD_PUBLISH,
    LOADED_PUBLISH,
    LOADED_PUBLISH_FAIL,
    LOAD_TOURNAMENTS,
    LOADED_TOURNAMENTS,
    LOADED_TOURNAMENTS_FAIL,
    SELECT_TOURNAMENT,
    SELECT_EVENT,
    POST_EVENT_SUCCESS,
    POST_EVENT_FAIL,
    POST_EVENT_SCORING_SUCCESS,
    POST_EVENT_SCORING_FAIL,
    POST_EVENT_LOADING,
    POST_EVENT_PRIZES_SUCCESS,
    LOAD_SCORING,
} from '../actions/types';


const initialState = {
    eventsLoading: true,
    tournamentsLoading: true,
    events: [],
    tournaments: [],
    selectedTournament: null,
    selectedEvent: null,
    createdEvent: null,
    createLoading: false,
    step: 1,
    scoring: [],
};

function eventsStateReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_EVENTS:
            return {
                ...state,
                eventsLoading: true,
                events: []
            }
        case LOADED_EVENTS:
            return {
                ...state,
                eventsLoading: false,
                events: payload
            }
        case LOADED_EVENTS_FAIL:
            return {
                ...state,
                eventsLoading: false,
                events: []
            }
        case LOAD_PUBLISH:
            return {
                ...state,
                eventsLoading: true,
                events: []
            }
        case LOADED_PUBLISH:
            return {
                ...state,
                eventsLoading: false,
                events: payload
            }
        case LOADED_PUBLISH_FAIL:
            return {
                ...state,
                eventsLoading: false,
                events: []
            }
        case SELECT_EVENT:
            return {
                ...state,
                selectedEvent: payload
            }
        case LOAD_TOURNAMENTS:
            return {
                ...state,
                tournamentsLoading: true,
                tournaments: []
            }
        case LOADED_TOURNAMENTS:
            return {
                ...state,
                tournamentsLoading: false,
                tournaments: payload
            }
        case LOADED_TOURNAMENTS_FAIL:
            return {
                ...state,
                tournamentsLoading: false,
                tournaments: []
            }
        case SELECT_TOURNAMENT:
            return {
                ...state,
                selectedTournament: payload
            }
        case POST_EVENT_LOADING:
            return {
                ...state,
                createLoading: true,
            }
        case POST_EVENT_SUCCESS:
            return {
                ...state,
                createdEvent: payload,
                createLoading: false,
                step: 2,
            }
        case POST_EVENT_FAIL:
            return {
                ...state,
                createEventError: payload,
                createLoading: false,
            }
        case POST_EVENT_SCORING_SUCCESS:
            return {
                ...state,
                createLoading: false,
                step: 3,
            }
        case POST_EVENT_SCORING_FAIL:
            return {
                ...state,
                createEventError: payload,
                createLoading: false,
            }
        case POST_EVENT_PRIZES_SUCCESS:
            return {
                ...state,
                createLoading: false,
                step: 4,
            }
        case LOAD_SCORING:
            return {
                ...state,
                scoring: payload,
            }
        default:
            return state
    }
}

export default eventsStateReducer;