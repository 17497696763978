import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch, connect} from 'react-redux';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import Checkbox from '../components/Checkbox';
import { updateUserProfile } from '../actions/userState';
import translate from "../reducers/translate";
import userProfileForm from "../constants/forms"
import {loadUser} from "../actions/auth";
import {translations} from "../constants/translations";



const phoneRegex = /^\+[1-9]{1,3}\d{6,10}$/;

const initUserProfileForm = {
  first_name: '',
  last_name: '',
  country: '',
  city: '',
  gender: '',
  phone_number: '',
  receive_new_event_notification: false,
};

const UserProfileEditForm = ({onClose, translation}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const countries = useSelector(state => state.utilsState.locations);
  const [formState, setFormState] = useState(initUserProfileForm);
  const [cityOptions, setCityOptions] = useState([]);
  const [errors, setErrors] = useState({ phone_number: '' });
  const GENDER_OPTIONS = [
    { id: '', name: translation.up_select_gender },
    { id: 'm', name: translation.up_male },
    { id: 'f', name: translation.up_female },
    { id: 'o', name: translation.up_other },
];

  useEffect(() => {
    if (user) {
      populateFormWithUserData();
    }
  }, [user]);

  useEffect(() => {
    updateCityOptions(formState.country);
  }, [formState.country, countries]);

  const populateFormWithUserData = () => {
    const updatedFormState = {
      first_name: user.account?.first_name || '',
      last_name: user.account?.last_name || '',
      country: user.country?.id.toString() || '',
      city: user.city?.id.toString() || '',
      gender: user.gender || '',
      phone_number: user.phone_number || '',
      receive_new_event_notification: user.receive_new_event_notification || false,
    };
    setFormState(updatedFormState);
  };

  const updateCityOptions = (selectedCountryId) => {
  if (selectedCountryId) {
    const country = countries.find(({ id }) => id.toString() === selectedCountryId);
    const updatedCityOptions = [{ id: '', name: translation.up_select_city }, ...(country?.cities.map(({ id, city_name }) => ({
      id: id.toString(), name: city_name
    })) || [])];
    setCityOptions(updatedCityOptions);
  } else {
    setCityOptions([{ id: '', name: translation.up_select_city }]);
  }
};

const handleInputChange = (e) => {
  const { name, type, value, checked } = e.target;
  setFormState(prevState => ({
    ...prevState,
    [name]: type === 'checkbox' ? checked : value,
    ...(name === 'country' && { city: '' }),
  }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!phoneRegex.test(formState.phone_number)) {
      isValid = false;
      newErrors.phone_number = translation.up_invalid_phone;
    }
    setErrors(newErrors);
    return isValid;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userData = {
        account: {
          first_name: formState.first_name,
          last_name: formState.last_name,
        },
        gender: formState.gender,
        country_id: parseInt(formState.country),
        city_id: parseInt(formState.city),
        phone_number: formState.phone_number,
        receive_new_event_notification: formState.receive_new_event_notification,
      };
      dispatch(updateUserProfile(userData)).then(() => {
        onClose();
        dispatch(loadUser());
      }).catch(error => {
        console.error("Failed to update user profile:", error);
      });
    }
  }

  const countryOptions = [{ id: '', name: translation.up_select_country }, ...countries.map(({ id, country_name }) => ({ id: id.toString(), name: country_name }))];
  const selectedCountryOption = countryOptions.find(option => option.id === formState.country);
  const selectedCityOption = cityOptions.find(option => option.id === formState.city);



  return (
    <form onSubmit={handleSubmit}>
      <Input name="first_name" label={translation.first_name} value={formState.first_name} onChange={handleInputChange} />
      <Input name="last_name" label={translation.last_name} value={formState.last_name} onChange={handleInputChange} />
      <Dropdown
        name="country"
        label={translation.country}
        placeholder={translate.country}
        options={countryOptions}
        selectedOption={selectedCountryOption}
        onSelect={handleInputChange}
        formAttr="country"
      />
      <Dropdown
        name="city"
        label={translation.city}
        options={cityOptions}
        selectedOption={selectedCityOption}
        onSelect={handleInputChange}
        formAttr="city"
        disabled={!formState.country}
      />
        <Dropdown
          name="gender"
          label={translation.gender}
          options={GENDER_OPTIONS}
          selectedOption={GENDER_OPTIONS.find(option => option.id.toString() === formState.gender)}
          onSelect={handleInputChange}
          formAttr="gender"
        />
      <Input
          name="phone_number"
          label={translation.phone_number}
          placeholder="+40721777999"
          type="tel"
          pattern="\+[1-9]{1,3}\d{6,10}"
          title={translation.up_phone_format}
          value={formState.phone_number}
          onChange={handleInputChange}
      />
              {errors.phone_number && <div style={{ color: 'red' }}>{errors.phone_number}</div>}

      <Checkbox
        name="receive_new_event_notification"
        label={translation.up_new_event_notification}
        checked={formState.receive_new_event_notification}
        onChange={handleInputChange}
      />
      <button type="submit" className="btn btn-light-blue float-end">{translation.up_save_changes}</button>
    </form>
  );
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(UserProfileEditForm);

