import React, { useState } from 'react';
import Input from '../components/Input';
import DropdownFishTypes from '../components/DropdownFishTypes';
import { connect } from 'react-redux';
import { IoTrashBin, IoAddCircleOutline } from "react-icons/io5";
import { loadEvent } from '../actions/eventsState';
import { eventFishScoringForm, eventFishScoringTopCatches } from '../constants/forms';
import getAxiosConfig from '../utils/axiosConfig';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialStateForm = {
  event: null,
  fish: null,
  accountable_catch_slots: 1,
  accountable_min_length: 0,
  under_min_length_points: 0,
}

const FishScoringEditForm = ({translation, fish_types, event, onClose, refreshEvent}) => {
  const [dirtyForm, setDirtyForm] = useState(false);
  const [fishScoringForm, setfishScoringForm] = useState([...event.event_fish_scoring]);
  const [formData] = useState(event.event_fish_scoring[0].scoring_type === 'Type_1' ? eventFishScoringForm : eventFishScoringTopCatches);
  const [isTopX] = useState(event.event_fish_scoring[0].scoring_type === 'Type_1' ? false : true);
  const [topX, setTopX] = useState(event.event_fish_scoring[0].top_x_catches);

  const handleChange = (e, indexScore) => {
    const { name, value } = e.target;
    const stateData = [...fishScoringForm];

    stateData[indexScore][name] = value;
    setfishScoringForm([...stateData]);
    setDirtyForm(true);
  };

  const addScoring = (e) => {
    e.preventDefault();
    const stateData = [...fishScoringForm];
    stateData.push({...initialStateForm, event: event.id});

    setfishScoringForm([...stateData]);
    setDirtyForm(true);
  };

  const deleteScoring = (e, indexScore) => {
    e.preventDefault();
    const stateData = [...fishScoringForm];

    if(stateData.length === 1) {
      return;
    }

    stateData.splice(indexScore, 1);

    setfishScoringForm([...stateData]);
    setDirtyForm(true);
  }

  const updateScoring = async (data) => {
    const config = getAxiosConfig();

    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event-fish-scoring/update/bulk/`, data, config)
      onClose();
      refreshEvent();
    } catch(err) {
      toast.error('Error while trying to update fish scoring, please try again later.');
    }
  }

  const nextStep = async (e) => {
    e.preventDefault();
    if(validateMandatory(fishScoringForm) && validateTopX()) {
      let data = [...fishScoringForm];

      for(let i = 0; i < data.length; i++) {
        data[i].top_x_catches = topX;

        if(topX) {
          data[i].scoring_type = "Top_X_Catches";
        } else {
          data[i].scoring_type = "Type_1";
        }
      }

      await updateScoring(data);
    } else {
      toast.error('Please complete all mandatory fields');
    }
  }

  const validateMandatory = (data) => {
    let validData = true;
    const arrayObjectKeys = Object.keys(initialStateForm);

    for(let i = 0; i < data.length; i++) {
      for(let k = 0; k < arrayObjectKeys.length; k++) {
        if(!data[i][arrayObjectKeys[k]]) {
          validData = false;
        }
      }
    }

    return validData;
  }

  const validateTopX = () => isTopX ? topX : true;

  const renderForm = (indexScore) => Object.keys(formData).map((key, index) => {
    switch(formData[key].type) {
        case 'number':
          return (
            <Input
              type="number"
              className="form-control"
              name={formData[key].label}
              label={translation[formData[key].label]}
              placeholder={translation[formData[key].placeholder]}
              value={fishScoringForm[indexScore][key] || ''}
              onChange={(e) => handleChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_fishtypes':
          return (
            <DropdownFishTypes
              type="text"
              className="form-control text-start"
              name={formData[key].label}
              label={translation[formData[key].label]}
              options={fish_types}
              selectedOptions={fishScoringForm[indexScore][formData[key].label]}
              onSelect={(e) => handleChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        default:
            return null
    }
  });

  return (<>
      <form className='bg-white rounded p-3'>
        <div className='info-container text-end color-green'>
          {`Updating fish scoring`}
        </div>

        {isTopX && <Input
          type="number"
          className="form-control"
          name={'topx'}
          label={translation.topx}
          placeholder={translation.topx}
          value={topX}
          onChange={(e) => setTopX(e.target.value)}
        />}
        {fishScoringForm?.map((_item, indexScore) => (
            <div className='scoring-container d-flex flex-column' key={`fishscoringset_${indexScore}`}>
            {renderForm(indexScore)}
            <button type="submit" className="btn red text-white submit-button align-self-end" onClick={(e) => deleteScoring(e, indexScore)}>
                <IoTrashBin className='ms-2' /> {translation.deleteScoring}
            </button>
            </div>
        ))}

        <div className="modal-footer p-0 pt-4 mt-2">
            <button type="submit" className="btn green text-white submit-button" onClick={(e) => addScoring(e)}>
              <IoAddCircleOutline /> {translation.addScoring}
            </button>
            <button type="button" className="btn btn-secondary blue" onClick={nextStep} disabled={!dirtyForm}>{translation.update_general}</button>
        </div>
      </form>
  </>
    
  );
};

const mapStateToProps = state => ({
    createdEvent: state.eventsState.createdEvent,
    translation: state.appState.translation,
    fish_types: state.utilsState.fish_types,
    event: state.eventState.event,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => dispatch(loadEvent(eventId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FishScoringEditForm)