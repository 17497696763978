import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const MultiDropdownSponsors = ({ label, options, onSelect, selectedOptions, formAttr }) => {
  const [selected, setSelected] = useState(label);
  // eslint-disable-line
  useEffect(() => {
    const filterSelectedOption = options?.filter((option) => {
      return selectedOptions?.filter((selectedOption) => selectedOption === option.id.toString()).length > 0});
    setSelected(filterSelectedOption && filterSelectedOption.length > 0 ? filterSelectedOption.map((item) => item.name).join(',') : label);
  }, [selectedOptions, options, label]);

  const handleSelect = (option) => {
    onSelect({target: {name: formAttr, value: option}});
  };

  return (
    <>
        <label htmlFor={label} className="form-label">
            { label }:
        </label>
        <Dropdown className='d-flex' onSelect={handleSelect}>
            <Dropdown.Toggle variant="light" id="multiSelectDropdown" className='flex-grow-1 text-start'>
                {selected}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options?.map((option) => (
                    <Dropdown.Item
                        key={option.id}
                        eventKey={option.id}
                        active={selectedOptions.includes(option.id.toString())}
                    >
                        {option.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    </>
    
  );
};

export default MultiDropdownSponsors;