import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { useNavigate, useLocation } from 'react-router-dom';

const Menu = ({ menuOpen, logout, menuRef, user, translation, closeMenu }) => {
   const navigate = useNavigate();
   const { pathname } = useLocation();

   const handleNavigation = (path) => {
      navigate(path);
      closeMenu();
   };

   const handleLogout = () => {
      logout();
      closeMenu();
   };

   return (
      <div className={`menu d-flex px-3 py-3 position-absolute  ${!menuOpen ? 'left' : 'start-0'}`} ref={menuRef}>
         <div className='top-menu d-flex flex-column flex-grow-1'>
               <img className='mw-100 px-5' src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png' alt='logo'/>
               <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/' && 'selected'}`} role="button"
                  onClick={() => handleNavigation("/")}><span>{translation.main_home}</span></p>
               {/* {user?.is_organiser && (
                  <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/myevents' && 'selected'}`} role="button"
                  onClick={() => handleNavigation("/myevents")}><span>My events</span></p>
               )} */}
               <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/events' && 'selected'}`} role="button"
                  onClick={() => handleNavigation("/events")}><span>{translation.main_events}</span></p>

               {/*<p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/tournaments' && 'selected'}`}*/}
               {/*   role="button" onClick={() => handleNavigation("/tournaments")}><span>Tournaments</span></p>*/}

               <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/club' && 'selected'}`}
                  role="button" onClick={() => handleNavigation("/club")}><span>Club</span></p>

               <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/contact' && 'selected'}`}
                  role="button" onClick={() => handleNavigation("/contact")}><span>Contact</span></p>

               {/*<p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/upload-fish' && 'selected'}`}*/}
               {/*   role="button" onClick={() => handleNavigation("/fish-upload")}><span>Upload fish</span></p>*/}

               {/* <p className={`menu-items mt-2 fw-bold py-2 my-0 ${pathname === '/events-ongoing' && 'selected'}`}
                  role="button" onClick={() => handleNavigation("/events-ongoing")}><span>Ongoing Events</span></p> */}

               <p className={`menu-items mt-2 fw-bold py-2 my-0 mt-auto`} role="button"
                  onClick={handleLogout}>{translation.main_logout}</p>
         </div>
         <div className='bottom-menu'></div>
      </div>
   );
};

const mapStateToProps = state => ({
    menuOpen: state.appState.menuOpen,
    user: state.auth.user,
    translation: state.appState.translation,
});

const mapDispatchToProps = (dispatch) => {
    return {
    logout: () => {
      dispatch(logout());
    },
    closeMenu: () => {
      dispatch({ type: 'CLOSE_MENU' });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
