import React from "react";
import { formatDateOnly } from "../helpers/utils";
import { IoSettingsOutline } from "react-icons/io5";

const ClubMembersTable = ({ members }) => {
  const renderRow = (member, index) => (
    <tr key={index}>
      <td>
        <img src={member.club_member_photo} className="rounded-circle club-members-img" alt={`${member.club_member_name}`} />
      </td>
      <td>{member.club_member_name}</td>
      <td>{formatDateOnly(member.club_member_join_date)}</td>
      <td className="d-none d-md-table-cell">
        {member.club_member_is_active ? "Active" : "Inactive"}
      </td>
      <td className=""><div role="button" className=""><IoSettingsOutline className="text-green fs-4" /></div></td>
    </tr>
  );

  return (
    <div className="club-members-table-container">
      <table className="table table-hover w-100 mt-3">
        <thead>
          <tr>
            <th className="green text-white"></th>
            <th className="green text-white">Name</th>
            {/*<th>Email</th> */}
            <th className="green text-white">Join date</th>
            <th className="green text-white d-none d-md-table-cell">Status</th>
            <th className="green text-white"></th>
          </tr>
        </thead>
        <tbody>{members?.map(renderRow)}</tbody>
      </table>
    </div>
  );
};

export default ClubMembersTable;
