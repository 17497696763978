import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {selectTournament} from '../actions/eventsState';
import {useNavigate} from "react-router-dom";
import { IoRibbonOutline } from 'react-icons/io5';

const TournamentCard = ({ tournament, selectTournament, translation }) => {
  const navigate = useNavigate();

  const handleSelectedTournament = useCallback(() => {
    selectTournament(tournament);
    navigate('/tournament');
  }, [tournament, navigate, selectTournament]);

  return (
    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex' key={`tournament_${tournament.id}`}>
      <div className='card w-100 pt-3'>
        <img className='mb-3' src={tournament['tournament_banner']} alt="tournament_banner"/>
        <p className='fw-bold'>{tournament['tournament_name']}</p>
        <div className='m-0 px-2 pt-1 d-flex pb-3'><IoRibbonOutline className='fs-4 me-2 color-green'/> <span>{tournament.events.length}</span></div>
        <button className='btn blue text-white mt-auto rounded-bottom' onClick={handleSelectedTournament}>Details</button> {/* Removed tournament from handleSelectedTournament call */}
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectTournament: (tournament) => {
      dispatch(selectTournament(tournament));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TournamentCard));