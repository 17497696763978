import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, resetContactForm } from '../actions/contactState';
import { useNavigate } from 'react-router-dom';

function ContactForm() {
    const [formData, setFormData] = useState({
        message: '',
        honeypot: ''
    });

    const { loading, error, success, translation } = useSelector((state) => ({
        loading: state.contactState.loading,
        error: state.contactState.error,
        success: state.contactState.success,
        translation: state.appState.translation
    }));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.honeypot) {
            return;
        }
        dispatch(sendMessage(formData));
    };

    useEffect(() => {
        if (success) {
            dispatch(resetContactForm());
            navigate('/thank-you');
            setFormData({
                message: '',
                honeypot: ''
            });
        } else if (error === "You have exceeded the number of allowed messages. Please try again later.") {
            const timer = setTimeout(() => {
                dispatch(resetContactForm());
                navigate('/login');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [success,error, navigate]);

    return (
        <div className="container mt-0">
            <div className="contact-form-container">
                <form onSubmit={handleSubmit} name="contact_form" method="post" className="contact-form">
                        <div className="mb-2">
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            placeholder={translation.contact_us_placeholder}
                            className="form-control contact-form-textarea"
                            rows="3"
                        ></textarea>
                        </div>
                        <div style={{display: 'none'}}>
                            <input
                                type="text"
                                name="honeypot"
                                value={formData.honeypot}
                                onChange={handleChange}
                                tabIndex="-1"
                            />
                        </div>
                        <div className="d-grid gap-1">
                        <button type="submit" disabled={loading} className="contact-form-button">
                            {loading ? translation.contact_us_sending : translation.contact_us_send}
                        </button>
                    </div>
                </form>
                {error && <p className="alert alert-danger mt-3">{error}</p>}
            </div>
        </div>
    );
}

export default ContactForm;

