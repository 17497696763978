import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationItem from './NotificationItem';
import { loadMyNotifications, markNotificationAsRead } from '../actions/myNotificationsState';
import Loader from "./Loader";
import { getUnreadNotificationsCount } from '../utils/notificationSelectors'


const NotificationsPage = ({ loadMyNotifications, markNotificationAsRead, notifications, loading, error, message, unreadCount  }) => {
    useEffect(() => {
        loadMyNotifications();
    }, [loadMyNotifications]);

    const handleMarkAsRead = (notificationId) => {
        markNotificationAsRead(notificationId);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="page-container container">
                    <h2 className="text-center mb-4">Notifications</h2>
                    {error && <p className="text-danger">Error loading notifications: {error}</p>}
                    {notifications.length === 0 ? (
                        <p>No notifications to show.</p>
                    ) : (
                        <div className="notifications-container">
                            {notifications.map(notification => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    onMarkAsRead={handleMarkAsRead}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    notifications: state.myNotificationsReducer.notifications,
    loading: state.myNotificationsReducer.loading,
    error: state.myNotificationsReducer.error,
    message: state.myNotificationsReducer.message,
    unreadCount: getUnreadNotificationsCount(state)
});

const mapDispatchToProps = {
    loadMyNotifications,
    markNotificationAsRead,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
