import {
    LOAD_MY_EVENTS,
    LOADED_MY_EVENTS,
    LOADED_MY_EVENTS_FAIL,
} from '../actions/types';

const initialState = {
    loading: false,
    events: [],
    event: null,
    error: null
};

export default function myEventsStateReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MY_EVENTS:
            return {
                ...state,
                loading: true,
            };

        case LOADED_MY_EVENTS:
            return {
                ...state,
                loading: false,
                events: action.payload,
            };
        case LOADED_MY_EVENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};