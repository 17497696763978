import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadProfilePhoto } from '../actions/userState';
import FishLoader from "./FishLoader";
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import getAxiosConfig from '../utils/axiosConfig';
import axios from 'axios';

const EditEventBanner = ({ eventId, onUploadDone }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const isImage = file && file['type'].split('/')[0] === 'image';
        if (!isImage) {
            console.error(`File is not an image. ${file.type}`);
            return;
        }
        setSelectedFile(file);
        setPreviewURL(URL.createObjectURL(file));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedFile) {
            setIsLoading(true);
            const config = getAxiosConfig();
            config.headers['Content-Type'] = 'multipart/form-data';

            const formData = new FormData();
            formData.append('event_logo', selectedFile);

            try {
                const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/events/${eventId}/update-logo/`, formData, config);
                toast.success('Photo updates, please refresh for changes.');
                setIsLoading(false);
                onUploadDone();
            } catch (err) {
                toast.error('Error while uploading photo');
                setIsLoading(false);
                onUploadDone();
            }
        }
    };

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 999 }}>
            <div style={{ position: 'relative', width: '300px', maxHeight: '80%', margin: '0 auto', top: '50%', transform: 'translateY(-50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '15px', overflowY: 'auto' }}>
                <IoClose role='button' onClick={() => onUploadDone()} size={40} className="modal-close text-end pb-2 position-absolute top-0 end-0"/>
                {previewURL && (
                    <div style={{ position: 'relative', textAlign: 'center', marginBottom: '20px' }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <img src={previewURL} alt="Profile preview" className="rounded-circle img-fluid" style={{ width: '150px', filter: isLoading ? 'blur(8px)' : 'none' }}/>
                            {isLoading && (
                                <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FishLoader />
                                </div>
                            )}
                        </div>
                        <form onSubmit={handleSubmit}>
                            <button type="submit" className="btn btn-light-blue" style={{ display: 'block', margin: '10px auto' }} disabled={isLoading}>Save Photo</button>
                        </form>
                    </div>
                )}
                {!previewURL && (
                    <div style={{ textAlign: 'center' }}>
                        <input type="file" id="fileInput" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }}/>
                        <button type="button" className="btn btn-light-blue" onClick={() => document.getElementById('fileInput').click()} style={{ display: 'block', margin: '0 auto' }}>Choose Photo</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditEventBanner;
