import React from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className="modal-overlay">
      <div className="modal-content bg-white rounded max-w-50">
        <div className="modal-close text-end pb-2"><IoClose className='fs-3' onClick={onClose} role="button"/></div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;