import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadEvents } from '../actions/eventsState';
import Loader from '../components/Loader';
import EventCard from '../components/EventCard';
 
const EventsOngoing = ({ events, eventsLoading, loadEvents, translation, user }) => {
  const navigate = useNavigate();
  const [enroll, setEnroll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    if(!events || events.length === 0) return;
    loadEvents(enroll);
  }, [enroll]);

  const handleSelectedEvent = useCallback((event) => {
    if(event.validator === user.id || event.is_owner) {
        navigate(`/validate/${event.id}`);
    } else {
        navigate(`/eventongoing/${event.id}`);
    }
  }, [navigate]);


  const renderEvents = useCallback(() => {
    return events.map((item) => {
      return (<EventCard key={item.id} event={item} onClick={() => handleSelectedEvent(item)}/>)
    });
  }, [events, handleSelectedEvent, translation]);

  return (
      <>
         
            <div className={`page-container container-fluid`}>
              <div className='row g-3'>
                {!eventsLoading ? 
                renderEvents()
                : <Loader />}
              </div>
            </div>
         
      </>
  );
}

const mapStateToProps = state => ({
    menuOpen: state.appState.menuOpen,
    events: state.eventsState.events,
    eventsLoading: state.eventsState.eventsLoading,
    translation: state.appState.translation,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvents: (enroll) => {
      dispatch(loadEvents(enroll))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventsOngoing));

