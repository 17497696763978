import React from 'react';

const Textarea = ({ name, value, onChange, label }) => {  

  return (
        <div className="mb-3">
          <label htmlFor={name} className="form-label">
            { label }:
          </label>
          <textarea
            className="form-control"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
          />
        </div>
    );
};

export default React.memo(Textarea);


