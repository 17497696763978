import {
  UPDATE_USER_PROFILE_FAIL, UPDATE_USER_PROFILE_STARTING, UPDATE_USER_PROFILE_SUCCESS, UPLOAD_PROFILE_PHOTO_FAIL,
  UPLOAD_PROFILE_PHOTO_LOADING,
  UPLOAD_PROFILE_PHOTO_SUCCESS
} from "../actions/types";

const initialState = {
  loading: false,
  data: null,
  error: null
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_PROFILE_PHOTO_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case UPLOAD_PROFILE_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Failed to upload profile photo.'
      };
    case UPDATE_USER_PROFILE_STARTING:
    return {
      ...state,
      loading: true,
    };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case UPDATE_USER_PROFILE_FAIL:
      return {
      ...state,
      error: action.payload,
      loading: false,
    };
    default:
      return state;
  }
};

export default profileReducer;