import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadTournaments, selectTournament } from '../actions/eventsState';
import Header from '../components/Header';
import Loader from '../components/Loader';
import UtilitiesTournaments from "../components/UtilitiesTournaments";
import TournamentCard from '../components/TournamentCard';



const Tournaments = ({ menuOpen, tournaments, tournamentsLoading, loadTournaments, selectTournament }) => {
  const navigate = useNavigate();
  const [searchTournament, setSearchTournament] = useState('');

  useEffect(() => {
    loadTournaments();
  }, []);

  const handleSelectedTournament = useCallback((tournament) => {
    selectTournament(tournament);
    navigate('/tournament');
  }, [selectTournament, navigate]);

  const filteredTournaments = tournaments.filter(tournament =>
    tournament.tournament_name.toLowerCase().includes(searchTournament.toLowerCase())
  );

  const renderTournaments = useCallback(() => {
    if (filteredTournaments.length < 1) {
      return null;
    }

    return filteredTournaments?.map((item, index) => {
      return (<TournamentCard tournament={item} />)
    });
  }, [filteredTournaments, handleSelectedTournament]);

  return (
      <>
          {!tournamentsLoading ? (
            <div className={`page-container container-fluid`}>
              <div className='row gx-3 g-3'>
                <UtilitiesTournaments onSearch={setSearchTournament} searchTerm={searchTournament} />
                {renderTournaments()}
              </div>
            </div>
          ) : <Loader />}

      </>
  );
}

const mapStateToProps = state => ({
    menuOpen: state.appState.menuOpen,
    tournaments: state.eventsState.tournaments,
    tournamentsLoading: state.eventsState.tournamentsLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadTournaments: () => {
      dispatch(loadTournaments())
    },
    selectTournament: (tournament) => {
      dispatch(selectTournament(tournament))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Tournaments));