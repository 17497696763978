import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from './ModalClubMembers';
import EditRolesForm from './EditRolesForm';
import ClubMembersTable from "./ClubMembersTable";
import { formatDateOnly } from "../helpers/utils";
import {updateRoles} from "../actions/clubActions";

const ClubCard = ({ club }) => {
    const navigate = useNavigate();
    const isClubOwner = useSelector(state => state.myClubStateReducer.is_club_owner);
    const location = useSelector(state => state.utilsState.locations);

    const [isEditRolesModalOpen, setEditRolesModalOpen] = useState(false);
    const dispatch = useDispatch();

    const handleShowEditRolesModal = () => {
        setEditRolesModalOpen(true);
    };
    const handleCloseModal = () => {
        setEditRolesModalOpen(false);
    };

    const handleSaveRoles = (updatedMembers) => {
        handleCloseModal();
        dispatch(updateRoles(updatedMembers));
    };

    const onClose = (e) => {
        e.stopPropagation();
        setEditRolesModalOpen(false);
    };

    const getCountryAndCity = (countryId, cityId) => {
        const countryObject = location?.find(country =>
            country.id === countryId
        );
        const cityObject = countryObject?.cities?.find(city => city.id === cityId);

        return {
            country: countryObject ? countryObject.country_name : '',
            city: cityObject ? cityObject.city_name : ''
        }
    }

   const {country, city} = getCountryAndCity(club.club_country, club.club_city);

    return (
        <>
            <div className='d-flex direction-row justify-content-between'>
                <h1 className='text-center mb-4'>{club?.club_name}</h1>
                <div className="club-logo d-flex flex-column">
                    <img src={club.club_logo} alt={`${club.club_name} Logo`} />
                    <button  className={`club-status btn btn-blue-light btn-disabled ${club.club_is_active ? 'active' : 'inactive'}`}>{club.club_is_active ? 'Active' : 'Inactive'}</button>
                </div>
            </div>
            <div className="club-info">
                <p className="club-owner-name fw-bold">{`Owner`}</p>
                <p>
                    <img src={club.club_owner_photo} alt={`${club.club_owner_name}'s`}
                         className="rounded-circle img-fluid" style={{ width: '50px', height: '50px' }}/>
                         {club.club_owner_name}
                </p>
                <p className="club-country-city">{`Country: ${country}, City: ${city}`}</p>
                <p className="club-creation-date">{`Creation Date: ${formatDateOnly(club.club_creation_date)}`}</p>
                
                {isClubOwner && (
                    <div className='d-flex justify-content-end'>
                        <button className='btn green text-white me-2' onClick={() => navigate('/send-club-invitation')}>Invite New Member</button>
                        <button className='btn green text-white' onClick={handleShowEditRolesModal}>Edit Club Members</button>
                    </div>
                )}
                <ClubMembersTable members={club.members}/>
            </div>
            {/* Modal for editing club member roles */}
            <Modal  isOpen={isEditRolesModalOpen} onClose={handleCloseModal}>
                <EditRolesForm members={club.members} onSave={handleSaveRoles} clubId={club.id} />
            </Modal>
        </>
    );
};

export default ClubCard;
