import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoReorderThree, IoRemoveOutline, IoNotificationsOutline } from 'react-icons/io5';
import Menu from "./Menu";
import { menuToggle } from './../actions/appState';
import useClickOutside from '../hooks/useClickOutside';
import NotificationsManager from './NotificationsManager';
import { hiddenMenuRoutes } from '../constants/enums';

const Header = ({ menuOpen, menuToggle, user }) => {
  const openMenuRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const clickOutside = useClickOutside(menuRef, openMenuRef);

  const showHeader = useMemo(() => {
    return !hiddenMenuRoutes?.includes(pathname);
  }, [pathname]);

  useEffect(() => {
    if(clickOutside && menuOpen) {
      menuToggle();
    }
  }, [clickOutside, menuToggle, menuOpen]);

  return showHeader && (
    <header className='header d-flex align-items-center ps-2' >
      <IoReorderThree className={`menu-icon text-white ${menuOpen && 'd-none'}`} role="button" onClick={() => setTimeout(() => menuToggle(), 50)} ref={openMenuRef} />
      <IoRemoveOutline className={`menu-icon text-white ${!menuOpen && 'd-none'}`} role="button" onClick={() => menuToggle()} />

      <img
        className='img-responsive px-3 py-2'
        src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png'
        alt='logo'
        style={{ height: '50px' }}
      />

      <div className='ms-auto d-flex align-items-center'>
        {user && (
          <>
            <img
              src={user.profile_picture}
              alt={`${user.account.first_name}'s profile`}
              className="rounded-circle"
              style={{ width: '30px', height: '30px', objectFit: 'cover', marginRight: '10px' }}
              role="button"
              onClick={() => navigate('/profile')}
            />
            <span className='text-white me-4'>{user.account.first_name}</span>
          </>
        )}

        <IoNotificationsOutline
          className="menu-icon me-3 text-white"
          style ={{ width: '30px', height: '30px'}}
          role="button"
          onClick={() => navigate('/notifications')}
        />
      </div>

      <NotificationsManager  />
      <Menu open={menuOpen} menuRef={menuRef} />
    </header>
  );
};

const mapStateToProps = (state) => ({
  menuOpen: state.appState.menuOpen,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    menuToggle: () => dispatch(menuToggle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
