import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadEvents, selectEvent } from '../actions/eventsState';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import UtilitiesEvents from '../components/UtilitiesEvents';
import EventCard from '../components/EventCard';
import Empty from '../components/Empty';
 
const Events = ({ events, eventsLoading, loadEvents, translation, selectEvent }) => {
  const navigate = useNavigate();
  const [enroll, setEnroll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    if(!events || events.length === 0) return;
    loadEvents(enroll);
  }, [enroll]);

  const handleSelectedEvent = useCallback((event) => {
    navigate(`/event/${event.id}`);
  }, [navigate])

  const filteredEvents = events.filter(event =>
    event.event_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderEvents = useCallback(() => {
    if (searchQuery.length < 3) {
      return events.map((item) => {
      return (<EventCard key={item.id} event={item} />)
    });
    }

    return filteredEvents.map((item) => {
      return (<EventCard key={item.id} event={item} />)
    });
  }, [filteredEvents, handleSelectedEvent, translation]);

  return (
      <>
         
            <div className={`page-container container-fluid`}>
              <div className='row g-3'>
                <UtilitiesEvents onSearch={setSearchQuery} translation={translation} refreshList={() => setEnroll(!enroll)} />
                {!eventsLoading ?
                  (events?.length < 1 || (searchQuery.length > 3 && filteredEvents?.length < 1)) ? <Empty label={`Nu exista evenimente.`}/> :
                  renderEvents()
                : <Loader />}
              </div>
            </div>
         
      </>
  );
}

const mapStateToProps = state => ({
    menuOpen: state.appState.menuOpen,
    events: state.eventsState.events,
    eventsLoading: state.eventsState.eventsLoading,
    translation: state.appState.translation
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvents: (enroll) => {
      dispatch(loadEvents(enroll))
    },
    selectEvent: (event) => {
      dispatch(selectEvent(event));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Events));

