import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { googleAuthenticate } from '../actions/auth';
import queryString from 'query-string';

const Google = ({ googleAuthenticate }) => {
    const location = useLocation();

    useEffect(() => {
        const { state, code } = queryString.parse(location.search);

        console.log('State:', state);
        console.log('Code:', code);

        if (state && code) {
            googleAuthenticate(state, code);
        }
    }, [location, googleAuthenticate]);

    return (
        <div className='container'>
            <div className='jumbotron mt-5'>
                <h1 className='display-4'>Streetfishing Romania </h1>
                <p className='lead'>Tournament Application</p>
                <hr className='my-4' />
                <p>Click the Log In button</p>
                <Link className='btn btn-primary btn-lg' to='/login'>Login</Link>
            </div>
        </div>
    );
};

export default connect(null, { googleAuthenticate })(Google);
