import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postPrizes } from '../actions/eventsState';


const EventFormSuccess = ({ translation }) => {
  const navigate = useNavigate();

  return (<>
    
    <div className={`page-container container-fluid d-flex justify-content-center align-items-center flex-column px-5`}>
      <p className='text-center'>{translation.success_message}</p>
      <button className=' btn green text-white' onClick={() => navigate('/myevents')}>{translation.cta_goToMyEvents}</button>
    </div>
  </>
    
  );
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postPrizes: (stepThreeData) => dispatch(postPrizes(stepThreeData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventFormSuccess))