export const getAxiosConfig = () => {
    if (localStorage.getItem('access')) {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
    }
    return {}
}

export default getAxiosConfig;