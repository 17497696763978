import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { photoTestUpload } from '../actions/utilsState';

const TestPhotoUploadForm = ({ photoTestUpload, uploadStatus, uploadMessage }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) {
            alert('Please select a file.');
            return;
        }
        photoTestUpload(file);
    };

    useEffect(() => {
        if (uploadStatus === 'success') {
            alert(`Upload Success: ${uploadMessage}`);
        } else if (uploadStatus === 'fail') {
            alert(`Upload Failed: ${uploadMessage}`);
        }
    }, [uploadStatus, uploadMessage]);

    return (
        <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange} />
            <button type="submit">Upload</button>
        </form>
    );
};

const mapStateToProps = state => ({
    uploadStatus: state.utilsState.status,
    uploadMessage: state.utilsState.message
});

export default connect(mapStateToProps, { photoTestUpload })(TestPhotoUploadForm);
