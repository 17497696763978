import {SEND_MESSAGE_FAIL, SEND_MESSAGE_START, SEND_MESSAGE_SUCCESS, RESET_CONTACT_FORM , MESSAGE_THROTTLED } from "../actions/types";

const initialState = {
    loading: false,
    error: null,
    success: null,
    message: ''
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_MESSAGE_START:
            return {
                ...state,
                loading: true,
                error: null,
                success: null,
            };
        case SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
                error: null,
                success: true
            };
        case SEND_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case MESSAGE_THROTTLED:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            };
        case RESET_CONTACT_FORM:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default contactReducer;
