import React from 'react';
import { formatDate } from "../helpers/utils";
import { useDispatch } from 'react-redux';
import { acceptInvitation } from "../actions/clubActions";
import { PiEnvelopeSimpleOpenLight , PiEnvelopeSimpleLight } from "react-icons/pi";
import { IoTimeOutline  } from 'react-icons/io5';


const NotificationItem = ({ notification, onMarkAsRead }) => {

    const dispatch = useDispatch();

    const handleMouseEnter = () => {
        if (!notification.is_read) {
            onMarkAsRead(notification.id);
        }
    };

    const handleAcceptInvitation = () => {
        dispatch(acceptInvitation(notification.notification_value));
    };

return (
    <div className={`notification-card ${!notification.is_read ? "unread" : ""}`} onMouseEnter={handleMouseEnter}>
        <div className="notification-content">
            <p className="notification-message m-0">
                {/* Conditionally render envelope icons based on the is_read status */}
                {notification.is_read ? (
                    <PiEnvelopeSimpleOpenLight />
                ) : (
                    <PiEnvelopeSimpleLight />
                )}
                {notification.message || "No message available."}
            </p>
            <p className="notification-date m-0">
                <IoTimeOutline /> {formatDate(notification.notify_date)}
            </p>
            {notification.notification_type === "NOTIFY_JOIN_CLUB_INVITE" && (
                <button onClick={handleAcceptInvitation} className='btn green text-white ms-auto'>
                    Accept Invitation
                </button>
            )}
        </div>
    </div>
);
};

export default NotificationItem;
