export const eventForm = {
  event_name: {
    type: 'input',
    label: 'event_name',
    placeholder: 'event_name'
  },
  event_logo: {
    type: 'upload_banner',
    label: 'event_logo',
    placeholder: 'event_logo'
  },
  details: {
    type: 'textarea',
    label: 'details',
    placeholder: 'details'
  },
  event_location_city: {
    type: 'dropdown_city',
    label: 'event_location_city',
    placeholder: 'event_location_city',
  },
  event_location: {
    type: 'dropdown_fishingspots',
    label: 'event_location',
    placeholder: 'event_location'
  },
  event_location_details: {
    type: 'input',
    label: 'event_location_details',
    placeholder: 'event_location_details'
  },
  start_date: {
    type: 'datetime',
    label: 'start_date',
  },
  event_duration_hours: {
    type: 'number',
    label: 'event_duration_hours',
    placeholder: 'event_duration_hours'
  },
  participation_tax: {
    type: 'number',
    label: 'participation_tax',
    placeholder: 'participation_tax'
  },
  principal_sponsors: {
    type: 'dropdown_sponsor',
    label: 'principal_sponsors',
    placeholder: 'principal_sponsors'
  },
  other_sponsors: {
    type: 'dropdown_sponsor',
    label: 'other_sponsors',
    placeholder: 'other_sponsors'
  },
  validator: {
    type: 'dropdown_validator',
    label: 'validator',
    placeholder: 'validator'
  },
}

export const eventFishScoringForm = {
  fish: {
    type: 'dropdown_fishtypes',
    label: 'fish',
    placeholder: 'fish'
  },

  accountable_catch_slots: {
    type: 'number',
    label: 'accountable_catch_slots',
    placeholder: 'accountable_catch_slots'
  },

  accountable_min_length: {
    type: 'number',
    label: 'accountable_min_length',
    placeholder: 'accountable_min_length'
  },

  under_min_length_points: {
    type: 'number',
    label: 'under_min_length_points',
    placeholder: 'under_min_length_points'
  },
}

export const eventFishScoringTopCatches = {
  fish: {
    type: 'dropdown_fishtypes',
    label: 'fish',
    placeholder: 'fish'
  },

  accountable_min_length: {
    type: 'number',
    label: 'accountable_min_length',
    placeholder: 'accountable_min_length'
  },

  under_min_length_points: {
    type: 'number',
    label: 'under_min_length_points',
    placeholder: 'under_min_length_points'
  },
}

export const eventPrizes = {
  prize_name: {
    type: 'string',
    label: 'prize_name',
    placeholder: 'prize_name'
  },

  prize_value: {
    type: 'string',
    label: 'prize_value',
    placeholder: 'prize_value'
  },
}

export const userProfileForm = {

  first_name: {
  type: 'string'  ,
  label: 'first_name',
  placeholder: 'first_name'
  },

  last_name: {
  type: 'string'  ,
  label: 'last_name',
  placeholder: 'last_name'
  },

  country: {
  type: 'dropdown_countries'  ,
  label: 'country',
  placeholder: 'country'
  },

  city: {
  type: 'dropdown_cities'  ,
  label: 'city',
  placeholder: 'city'
  },

  gender: {
  type: 'dropdown_genders'  ,
  label: 'gender',
  placeholder: 'gender'
  },

  phone_number: {
  type: 'string'  ,
  label: 'phone_number',
  placeholder: 'phone_number'
  },

  receive_new_event_notification: {
  type: 'bool'  ,
  label: 'receive_new_event_notification',
  placeholder: 'receive_new_event_notification'
  }
}