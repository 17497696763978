import {
    LOAD_MY_NOTIFICATIONS,
    LOADED_MY_NOTIFICATIONS,
    LOADED_MY_NOTIFICATIONS_FAIL,
    MARK_NOTIFICATION_AS_READ,
} from '../actions/types';

const initialState = {
    loading: false,
    notifications: [],
    error: null,
    message:null,
};

export default function myNotificationsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MY_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LOADED_MY_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                notifications: action.payload.notifications,
                error: null
            };
        case LOADED_MY_NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case MARK_NOTIFICATION_AS_READ:
            const updatedNotifications = state.notifications.map(notification => {
                if (notification.id === action.payload.notificationId) {
                    return { ...notification, is_read: action.payload.is_read };
                }
                return notification;
            });
            return { ...state, notifications: updatedNotifications };

        default:
            return state;
    }
}
