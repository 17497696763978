import React from 'react';
import { IoRibbon } from 'react-icons/io5';
import { connect } from 'react-redux';

const classNamesRibbon = [
    'golden',
    'silver',
    'bronze',
]

const FishPrizesTable = ({ fishPrizes, translation }) => {
    const renderRow = (item, key) => {

        return (
            <tr key={key}>
                <th scope="row">{key + 1}</th>
                <td>{key < 3 ? <IoRibbon className={classNamesRibbon[key]}/> : null}</td>
                <td>{item.prize_name}</td>
                <td>{item.prize_value}</td>
            </tr>
        );
    }

    return (
        <>
            <div className='col-12 d-flex m-0 mt-3 flex-column justify-items-center'>
                <table className="table table-hover w-100">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"></th>
                            <th scope="col">{translation.prize_name}</th>
                            <th scope="col">{translation.prize_value}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fishPrizes.map((item, index) => renderRow(item, index))}
                    </tbody>
                </table>
            </div>
        </>
    );
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(FishPrizesTable);