import {
    LOAD_TRANSLATIONS,
    MENU_TOGGLE,
    CLOSE_MENU
} from '../actions/types';
import { translations } from '../constants/translations';

const storedLanguage = localStorage.getItem('language');

const initialState = {
    menuOpen: false,
    language: storedLanguage || 'ro',
    translation: translations[storedLanguage || 'ro'],
};

function appStateReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case MENU_TOGGLE:
            return {
                ...state,
                menuOpen: !state.menuOpen
            }
        case CLOSE_MENU:
            return {
            ...state,
            menuOpen: false
        }
        case LOAD_TRANSLATIONS:
            return {
                ...state,
                language: payload,
                translation: translations[payload]
            }
        default:
            return state
    }
}

export default appStateReducer;