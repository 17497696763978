import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, resetContactForm } from '../actions/contactState';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from 'react-toastify';

function NonAuthContactForm() {
    const [formData, setFormData] = useState({
        message: '',
        name: '',
        email: '',
        honeypot: ''
    });

    const { loading, error, success, translation } = useSelector((state) => ({
        loading: state.contactState.loading,
        error: state.contactState.error,
        success: state.contactState.success,
        translation: state.appState.translation
    }));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [captchaValue, setCaptchaValue] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.honeypot) {
            return;
        }
        if (captchaValue) {
            dispatch(sendMessage({ ...formData, captcha: captchaValue }));
        } else {
            toast.error('Please complete the CAPTCHA');
        }
    };

    useEffect(() => {
        if (success) {
            navigate('/thank-you');
            setFormData({
                message: '',
                name: '',
                email: '',
                honeypot: ''
            });
            setCaptchaValue(null);
        } else if (error === "You have exceeded the number of allowed messages. Please try again later.") {
            const timer = setTimeout(() => {
                dispatch(resetContactForm());
                navigate('/login');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [success, error, navigate, dispatch]);

    return (
        <div className="container mt-2">
            <div className="contact-form-container">
                <form onSubmit={handleSubmit} name="contact_form" method="post" className="contact-form">
                    <div className="mb-3">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            placeholder={translation.contact_name_placeholder}
                            className="form-control contact-form-input"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            placeholder={translation.contact_email_placeholder}
                            className="form-control contact-form-input"
                        />
                    </div>
                    <div className="mb-3">
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            placeholder={translation.contact_us_placeholder}
                            className="form-control contact-form-textarea"
                            rows="3"
                        ></textarea>
                    </div>
                    <div style={{ display: 'none' }}>
                        <input
                            type="text"
                            name="honeypot"
                            value={formData.honeypot}
                            onChange={handleChange}
                            tabIndex="-1"
                        />
                    </div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={setCaptchaValue}
                        className='d-flex justify-content-center my-3'
                    />
                    <div className="d-grid gap-2">
                        <button type="submit" disabled={loading} className="contact-form-button">
                            {loading ? translation.contact_us_sending : translation.contact_us_send}
                        </button>
                    </div>
                </form>
                {error && <p className="alert alert-danger mt-3">{error}</p>}
            </div>
        </div>
    );
}

export default NonAuthContactForm;
