import { combineReducers } from 'redux';
import auth from './auth';
import appState from './appState';
import eventsState from './eventsState';
import eventState from './eventState';
import utilsState from './utilsState';
import clubInvitation from "./clubInvitation";
import notification from "./notification";
import myEventsStateReducer from "./myEventsState";
import myMapCatchDataReducer from "./myCatchMapDataState";
import myClubStateReducer from "./myClubState";
import myNotificationsReducer from "./myNotificationsState";
import contactState from "./contactState"
import userState from "./userState"

export default combineReducers({
    auth,
    appState,
    eventsState,
    eventState,
    utilsState,
    clubInvitation,
    notification,
    myEventsStateReducer,
    myMapCatchDataReducer,
    myClubStateReducer,
    myNotificationsReducer,
    contactState,
    userState

});
