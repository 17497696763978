import React, { useEffect } from 'react';

const Notification = ({ notification, clearNotification }) => {

    useEffect(() => {
        setTimeout(() => {
            clearNotification();
        }, 5000);
    }, []);

    const renderNotificationType = () => {
        switch(notification.type) {
            case 'warning':
                return (
                    <div className='notification p-2 mb-2 galben'>
                        {notification.message}
                    </div>
                )
                break;
            case 'error':
                return (
                    <div className='notification p-2 mb-2 red'>
                        {notification.message}
                    </div>
                )
                break;
            default:
                return (
                    <div className='notification p-2 mb-2 red'>
                        {notification.message}
                    </div>
                )
                break;
        }
    }


  return (
    <div className='notifications-container'>
        {renderNotificationType()}
    </div>
);
};

export default React.memo(Notification);


