import {connect, useDispatch, useSelector} from 'react-redux';
import Loader from '../components/Loader';
import { IoPhonePortraitOutline , IoLocationOutline, IoNotificationsOutline, IoCameraReverse,  } from 'react-icons/io5';
import React, {useState} from "react";
import EditProfilePicture from "../components/EditProfilePicture";
import {loadUser} from "../actions/auth";
import UserProfileEditForm from '../components/UserProfileEditForm';
import axios from "axios";
import {toast} from "react-toastify";
import getAxiosConfig from "../utils/axiosConfig";


const UserProfile = ({ translation }) =>  {
  const user = useSelector(state => state.auth.user);
  const isLoading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();

  const [isEditingProfilePicture, setIsEditingProfilePicture] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);


    const handleEditProfilePicture = () => {
        setIsEditingProfilePicture(true);
    };

    const handleEditPictureDone = () => {
        setIsEditingProfilePicture(false);
        dispatch(loadUser());
    };

    const toggleEditDetails = () => {
        setIsEditingDetails(!isEditingDetails);
    };

    const handleDeleteAccountRequest = async () => {
        try {
            const config = getAxiosConfig();
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/delete-account-request/`, {}, config);
            toast.success(translation.delete_account_request_success);
        } catch (err) {
            toast.error(translation.delete_account_request_error);
        }
    };


  const GENDER_MAP = { 'm': translation.up_male, 'f': translation.up_female, 'o': translation.up_other };
  if (isLoading || !user) {
    return <Loader />;
  }

  const {
    account: {
      id: accountId,
      first_name,
      last_name,
    } = {},
    profile_picture,
    gender,
    phone_number,
    receive_new_event_notification,
  } = user ?? {};

  const countryName = user.country?.country_name ?? '';
  const cityName = user.city?.city_name ?? '';
  const genderFull = GENDER_MAP[gender];

 return (
  <section style={{ backgroundColor: "#eee" }}>
    <div className="page-container container-fluid">
      <div className="row">
        <div className="col-lg-4">
          <div className="card mb-4">
            <div className="card-body text-center">
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <img src={profile_picture} alt="avatar" className="rounded-circle img-fluid profile-picture" />
                <IoCameraReverse
                  onClick={handleEditProfilePicture}
                  size={30}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    cursor: 'pointer',
                    color: 'rgba(0, 0, 0, 0.5)'
                  }}
                />
              </div>
              <h5 className="custom-title mt-1" style={{ fontSize: '1.25rem' }}>{`${first_name} ${last_name}`}</h5>
              <p className="text-muted mb-4 color-green">
                <IoLocationOutline style={{ marginRight: '5px', color: 'green' }} size={20} /> {`${countryName}, ${cityName}`}
              </p>
              <div className="d-flex justify-content-center mb-2">
                {isEditingProfilePicture && <EditProfilePicture onUploadDone={handleEditPictureDone} />}
              </div>
              <div className="d-flex justify-content-center mb-2">
                <button type="button" className="btn btn-light-blue" onClick={toggleEditDetails}>{translation.up_edit}</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          {isEditingDetails ? (
            <UserProfileEditForm onClose={toggleEditDetails} />
          ) : (
            <div className="card mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <p className="mb-0">{translation.first_name}</p>
                    <p className="text-muted mb-0">{first_name}</p>
                  </div>
                  <div className="col-sm-6">
                    <p className="mb-0">{translation.last_name}</p>
                    <p className="text-muted mb-0">{last_name}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <p className="mb-0">{translation.country}</p>
                    <p className="text-muted mb-0">{countryName}</p>
                  </div>
                  <div className="col-sm-6">
                    <p className="mb-0">{translation.city}</p>
                    <p className="text-muted mb-0">{cityName}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">{translation.gender}</p>
                    <p className="text-muted mb-0">{genderFull}</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="mb-0">{translation.phone_number}</p>
                    <p className="text-muted mb-0">
                      <IoPhonePortraitOutline style={{ marginRight: '5px', color: 'green' }} size={18} /> {phone_number}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">
                      <IoNotificationsOutline style={{ marginRight: '5px', color: 'green' }} size={20} /> {translation.notifications}
                    </p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{receive_new_event_notification ? translation.yes : translation.no}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
           {/* Add delete account link */}
            <div className="text-center mt-3">
              <a
                href="#"
                onClick={handleDeleteAccountRequest}
                style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {translation.delete_account}
              </a>
            </div>
        </div>
      </div>
    </div>
  </section>
);
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(UserProfile);
