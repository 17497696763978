import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import { toast } from 'react-toastify';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getOs = () => {
  let OSName="Unknown OS";
  if (navigator.userAgent.indexOf("Win")!==-1) OSName="Windows";
  if (navigator.userAgent.indexOf("Mac")!==-1) OSName="MacOS";
  if (navigator.userAgent.indexOf("X11")!==-1) OSName="UNIX";
  if (navigator.userAgent.indexOf("Linux")!==-1) OSName="Linux";
  return OSName;
}

export const requestUserPermission = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: process.env.VAPIDKEY });
    if (currentToken) {
      console.log('Token:', currentToken);
      await sendTokenToServer(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

const sendTokenToServer = async (token) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const authToken = localStorage.getItem('access');
  const device = 'web';
  const os = getOs();

  try {
    const response = await fetch(`${apiUrl}/v1/fcm-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${authToken}`,
      },
      body: JSON.stringify({ token, device, os }),
    });

    if (!response.ok) {
      console.error('Failed to send token to server:', response.statusText);
      return;
    }

    console.log('Token successfully sent to the server');
  } catch (error) {
    console.error('Error sending token to server:', error);
  }
};

// Foreground message listener
export const onForegroundMessageListener = () => {
  onMessage(messaging, (payload) => {
    if (document.visibilityState === 'visible') {
      const { title, body, click_action } = payload.data;

      toast.info(`${title}: ${body}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          if (click_action) {
            window.open(click_action, '_blank');
          }
        }
      });
    }
    // If the app is in the background, do nothing
    // the service worker will handle displaying the notification
  });
};

