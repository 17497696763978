import React, { useState, useRef, useEffect } from 'react';

const Dropdown = ({ label, options, onSelect, selectedOption, formAttr }) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onSelect({target: {name: formAttr, value: option.id}});
    setIsOpen(false);
  };

  return (
    <div className="mb-3 position-relative" ref={dropdownRef}>
      <label htmlFor="selectedOption" className="form-label w-100">
        { label }:
      </label>
      <button className="btn dropdown-button dropdown-toggle" onClick={(event) => toggleDropdown(event)}>
        {selectedOption?.name}
      </button>
        <div className={`dropdown-menu ${isOpen ? 'd-block' : ''}`}>
          {options?.map((option) => (
            <div
              key={option.id}
              className="dropdown-item"
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
    </div>
  );
};

export default Dropdown;