import React, { useState } from 'react';

const EditRolesForm = ({ members, onSave, clubId }) => {
  const [formState, setFormState] = useState(members.map(member => ({
    id: member.id,
    name: member.club_member_name,
    club_member_can_create: member.club_member_can_create || false,
    club_member_can_approve: member.club_member_can_approve || false,
    club_member_can_edit: member.club_member_can_edit || false,
    club_member_can_view: member.club_member_can_view || false,
    club_member_can_validate: member.club_member_can_validate || false,
  })));



  const handleChange = (memberId, role, value) => {
    setFormState(currentState =>
      currentState.map(member =>
        member.id === memberId ? { ...member, [role]: value } : member
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({
      club_id: clubId,
      memberships: formState.map(({ id, club_member_can_create, club_member_can_approve, club_member_can_edit, club_member_can_view, club_member_can_validate }) => ({
        id,
        club_member_can_create,
        club_member_can_approve,
        club_member_can_edit,
        club_member_can_view,
        club_member_can_validate,
      })),
    });
  };



  return (
    <form onSubmit={handleSubmit}>
      <table>
        <thead>
          <tr>
            <th>Member</th>
            <th>Can Create</th>
            <th>Can Approve</th>
            <th>Can Edit</th>
            <th>Can View</th>
            <th>Can Validate</th>
          </tr>
        </thead>
        <tbody>
          {formState.map(member => (
            <tr key={member.id}>
              <td>{member.name}</td>
              {['club_member_can_create', 'club_member_can_approve', 'club_member_can_edit', 'club_member_can_view', 'club_member_can_validate'].map(role => (
                <td key={role}>
                  <input
                    type="checkbox"
                    checked={member[role]}
                    onChange={(e) => handleChange(member.id, role, e.target.checked)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button className='float-end btn green text-white mt-4' type="submit">Save Changes</button>
    </form>
  );
};

export default EditRolesForm;
