import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoCaretDownOutline, IoFishSharp, IoRibbon } from 'react-icons/io5';
import { connect } from 'react-redux';

const ScoringTable = ({ scoring, event, translation, userId = false }) => {
    const [expand, setExpand] = useState(-1);
    const [biggestCatch, setBiggestCatch] = useState(null);
    const [fishNameCatch, setFishNameCatch] = useState(null);
    const [tableScoring, setTableScoring] = useState(scoring);
    const [modalView, setModalView] = useState(null);

    useEffect(() => {
        if(!scoring || !scoring.length) return;
        const filterBiggestCatch = scoring.reduce((prev, current) => (prev && prev.biggest_catch > current.biggest_catch) ? prev : current, scoring[0]);
        setBiggestCatch(filterBiggestCatch);
        setFishNameCatch(filterBiggestCatch?.details.find(item => filterBiggestCatch.biggest_catch === item.length));
    }, [scoring]);

    useEffect(() => {
        if(!userId) return;
        let getPositionIndex = scoring.findIndex(item => item.user === userId);
        if(getPositionIndex === 0) {
            getPositionIndex = 1;
        } else if(getPositionIndex + 1 === scoring.length) {
            getPositionIndex -= 1;
        }
        const tableScoringMap = scoring.filter((item, index) => (index === getPositionIndex) || (index > getPositionIndex && index - 1 === getPositionIndex) || (index < getPositionIndex && index + 1 === getPositionIndex));
        setTableScoring(tableScoringMap);
    }, [userId]);

    const renderCatch = (item, key) => {
        return (
            <tr key={`catchData${key}`} onClick={() => setModalView(item.photo_url)}>
                <td></td>
                <td></td>
                <td className="position-relative">
                    {item.fish_name}
                    <span className='position-absolute catch-hour'>
                        {new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() :`0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() :`0${new Date(item.upload_date).getMinutes()}`} {item.entity_initials}
                    </span>
                </td>
                <td className=""></td>
                <td className=""></td>
                <td className="">{item.length} cm</td>
                <td className="">{item.points}</td>
                <td>{item.is_valid_fish ? (<IoFishSharp className="color-green" />) : (<IoFishSharp className='color-red' />)}</td>
            </tr>
        )
    }

    const renderRow =(item, key) => {
        return (
            <>
                <tr key={`rowData${key}`} onClick={() => setModalView(item.photo_url)}>
                    <td><IoCaretDownOutline className={`color-green ${key === expand ? 'rotate-180' : ''}`} role="button" onClick={(event) => {setExpand(key === expand ? -1 : key )}}/></td>
                    <td className="">{item.is_team_event ? item.team_rank : item.user_rank}</td>
                    <td className="">{item.is_team_event ? item.team_name : item.user_name}</td>
                    <td className="">{item.total_calculated_fishes}</td>
                    <td className="">
                        {item.total_points} {event?.has_bonus_points && <small>({item.bonus_points})</small>}
                    </td>
                    <td className="">
                        {item.total_species}
                    </td>
                    <td className="">
                        {item.biggest_catch} cm
                    </td>
                    <td className="">
                        {item.average_catch} cm
                    </td>
                    
                </tr>
                {expand > -1 && expand === key && (
                    <>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{translation.cls_fish}</th>
                            <th></th>
                            <th></th>
                            <th>{translation.cls_length}</th>
                            <th>{translation.cls_points}</th>
                            <th>{translation.cls_valid}</th>
                        </tr>
                            
                        {item.details.map(renderCatch)}
                    </>
                    
                )}
            </>
            
        );
    };

    return (
        <>
            <table className='table table-hover table-catch w-100 mt-3'>
                <thead>
                    <tr>
                        <th className="galben text-white"></th>
                        <th className="galben text-white">{translation.cls_rank}</th>
                        <th className="galben text-white">{translation.cls_name}</th>
                        <th className="galben text-white">{translation.cls_total_caught}</th>
                        <th className="galben text-white">{translation.cls_points}</th>
                        <th className="galben text-white">{translation.cls_fish}</th>
                        <th className="galben text-white">{translation.cls_cmmc}</th>
                        <th className="galben text-white">{translation.cls_average}</th>
                    </tr>
                </thead>
                <tbody>
                    {biggestCatch && fishNameCatch && <tr>
                        <td></td>
                        <td className="">{translation.cls_cmmc}</td>
                        <td className="">{biggestCatch.is_team_event? biggestCatch.team_name : biggestCatch.user_name}</td>
                        <td className=""></td>
                        <td className="">
                            
                        </td>
                        <td className="">
                            {fishNameCatch.fish_name}
                        </td>
                        <td className="">
                            {biggestCatch.biggest_catch} cm
                        </td>
                        <td className="">
                            
                        </td>
                        
                    </tr>}
                    {scoring?.map(renderRow)}
                </tbody>
            </table>
            <Modal show={modalView} onHide={() => setModalView(null)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Captura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className='mw-100' src={modalView || null} alt={`captura`}/>
                </Modal.Body>
            </Modal>
        </>
    );
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(ScoringTable);