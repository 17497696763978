import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadEvent, loadScoring } from '../actions/eventsState';
import { loadMyCatchMapData } from '../actions/myCatchMapDataState';
import {Modal} from 'react-bootstrap';
import Loader from '../components/Loader';
import Input from '../components/Input';
import DropdownFishTypes from '../components/DropdownFishTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import MyEventCatchMap from '../components/MapForSingleFish';
import { IoCheckmark, IoCheckmarkDoneSharp, IoClose, IoCloudUploadOutline, IoEye } from 'react-icons/io5';
import FishLoader from '../components/FishLoader';
import ScoringTable from '../components/ScoringTable';

const EventOngoing = ({ event, translation, mapdata, loadEvent, loadMyCatchMapData, fish_types, loadScoring, scoring, user }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const { eventId } = useParams();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedFish, setSelectedFish] = useState(null);
  const [selectedFishType, setSelectedFishType] = useState(null);
  const [selectedFishLength, setSelectedFishLength] = useState('');
  const [showMapModal, setShowMapModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scoringView, setScoringView] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);


  useEffect(() => {
    if(!eventId) {
      navigate('/');
    }

    loadEvent(eventId);
    loadScoring(eventId);
    loadMyCatchMapData(eventId);
  }, [loadEvent, eventId, navigate, loadMyCatchMapData]);


  useEffect(() => {
    if(event && (!event?.is_ongoing || (event?.enrollement_status === 'not enrolled' || event?.enrollement_status === 'rejected' ))) {

        navigate('/');
    }
  }, [event]);

  useEffect(() => {
    if(event) {
        const fishScoring = event.event_fish_scoring;
        setSelectedFish(fishScoring.map((item) => item.fish));
    }
  }, [event]);

  useEffect(() => {
    const requestLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setLocationError(null);
                },
                (error) => {
                    setLocationError('Location access denied or unavailable. Defaulting to null coordinates.');
                    setLocation({ latitude: null, longitude: null });
                }
            );
        } else {
            setLocationError('Geolocation is not supported by this browser. Defaulting to null coordinates.');
            setLocation({ latitude: null, longitude: null });
        }
    };

    requestLocation();
    }, []);


  const handlePhotoInput = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
    event.target.value = '';
  }

  const handleFishLengthChange = (event) => {
    setSelectedFishLength(event.target.value);
  }

   const handleFishType = (event) => {
    setSelectedFishType(event.target.value);
  }

  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    const formattedLatitude = (location.latitude !== null) ? location.latitude.toFixed(7) : "0.0000000";
    const formattedLongitude = (location.longitude !== null) ? location.longitude.toFixed(7) : "0.0000000";

    const formData = new FormData();
    formData.append('photo', selectedPhoto);
    formData.append('event', eventId);
    formData.append('fish', selectedFishType);
    formData.append('fish_length', selectedFishLength);
    formData.append('latitude', formattedLatitude);
    formData.append('longitude', formattedLongitude);
    // console.log("FormData sent:");
    // for (let [key, value] of formData.entries()) {
    //     console.log(`${key}: ${value}`);
    // }
    
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/upload-photo/`, formData, config);
        toast.success('You have uploaded a fish');
        loadMyCatchMapData(eventId);
        setSelectedPhoto(null);
        setSelectedFishType(null);
        setSelectedFishLength('');
        setLoading(false);
    } catch (error) {
        if (error.response && error.response.data) {
            if (error.response.data.detail) {
                toast.error(error.response.data.detail);
            } else if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('An error occurred');
            }
        }
        loadMyCatchMapData(eventId);
        setSelectedPhoto(null);
        setSelectedFishType(null);
        setSelectedFishLength('');
        setLoading(false);
    }
  };

  const handleUpload = () => {
    ref.current.click();
  }

  const handleDeletePhoto = () => {
    setSelectedPhoto(null);
  }


  return (
    <div className={`page-container container-fluid bg-white`}>
        <div className='row gx-3 g-3'>
            {(event && mapdata) ? (
                <>
                    <h5>{event.event_name}</h5>
                    <form>
                        <div className='step'>
                            <div className="mb-3 d-none">
                                <label htmlFor={translation.ong_upload} className="form-label">
                                    { translation.ong_upload }:
                                </label>
                                <input
                                    type={`file`}
                                    accept="image/*"
                                    className={`form-control step d-none`}
                                    ref={ref}
                                    name={translation.ong_upload}
                                    label={translation.ong_upload}
                                    onChange={(e) => handlePhotoInput(e)}
                                />
                            </div>
                            {!selectedPhoto ? (                                
                                <div className='upload-watermark text-center mb-2' onClick={handleUpload} role="button">
                                    {translation.ong_upload} <IoCloudUploadOutline className='fs-5' />
                                </div>
                            ) : (
                                <div className='event-actions text-center mb-2 position-relative pe-4'>
                                    <img className='image-upload-capture' src={URL.createObjectURL(selectedPhoto)} alt="" style={{filter: loading ? 'blur(8px)' : 'none'}} />
                                    <IoClose className='fs-1 photo-delete-icon position-absolute color-red end-0' onClick={handleDeletePhoto} />
                                    {loading && (
                                        <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <FishLoader />
                                        </div>
                                    )}
                                </div>
                            )}

                            
                            <DropdownFishTypes
                                type="text"
                                className="form-control text-start"
                                name={translation.fish_type}
                                label={translation.fish_type}
                                options={fish_types?.filter((item) => selectedFish?.includes(item.id))}
                                selectedOptions={selectedFishType}
                                onSelect={handleFishType}
                            />
                            <Input
                                type="number"
                                className="form-control step"
                                name={`Fish Length`}
                                label={translation.ong_fish_length}
                                placeholder={translation.ong_fish_length}
                                onChange={handleFishLengthChange}
                                value={selectedFishLength}
                            />

                            <button className='btn green text-white' disabled={!selectedFishLength || !selectedFishType || !selectedPhoto|| loading} onClick={handleFormSubmit}>
                                {translation.upload_fish}
                            </button>
                        </div>
                        
                    </form>
                    <button 
                        className='btn blue text-white' 
                        onClick={() => setScoringView(!scoringView)} 
                        // disabled={scoring.find(item => item.user === user?.account?.id)?.user_rank ? false : true}
                        disabled={!scoring}
                    >
                        {scoringView ? translation.cls_total_caught : translation.ranking}
                    </button>
                    {!scoringView ? (
                        <table className='table table-hover table-catch w-100 mt-3'>
                        <thead>
                            <tr>
                                <th className="galben text-white">{translation.cls_fish}</th>
                                <th className="galben text-white">{translation.cls_length}</th>
                                <th className="galben text-white">{translation.cls_status}</th>
                                <th className="galben text-white"></th>
                            </tr>
                        </thead>
                        <tbody>
                            { mapdata?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="position-relative">
                                            {item.fish_name}
                                            <span className='position-absolute catch-hour'>{new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() : `0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() : `0${new Date(item.upload_date).getMinutes()}`}</span>
                                        </td>
                                        <td className="">{item.fish_length} cm</td>
                                        <td className="">{item.fish_status ?  <IoCheckmarkDoneSharp className="color-green" /> : (item.is_rejected ? <IoClose className='color-red' /> : <IoCheckmark className='color-galben' />)}</td>
                                        <td className=""><IoEye role="button" onClick={() => setShowMapModal(item)} /></td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                        
                        </table>
                    ) : (
                        <ScoringTable scoring={scoring} event={event} />
                    )}
                </>
            ): (
                <Loader />
            )}

            <Modal show={showMapModal ? true : false} onHide={() => setShowMapModal(null)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{translation.ong_my_catch}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showMapModal && (
                        <>
                            <img className='w-100' src={showMapModal?.photo_url} alt=""/>
                            {/* <Modal.Title>{translation.ong_map}</Modal.Title>
                            <MyEventCatchMap 
                                lat={showMapModal?.gps_latitude}
                                lng={showMapModal?.gps_longitude}
                                event_name={showMapModal?.event_name}
                                fish_name={showMapModal?.fish_name}
                                photo_url={showMapModal?.photo_url}
                                fish_length={showMapModal?.fish_length}
                            /> */}
                            
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
    event: state.eventState.event,
    eventLoading: state.eventState.eventLoading,
    mapdata: state.myMapCatchDataReducer.mapdata,
    fish_types: state.utilsState.fish_types,
    translation: state.appState.translation,
    scoring: state.eventsState.scoring,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
     loadEvent: (eventId) => {
      dispatch(loadEvent(eventId))
    },
    loadMyCatchMapData: (eventId) => {
        dispatch(loadMyCatchMapData(eventId));
    },
    loadScoring: (eventId) => {
        dispatch(loadScoring(eventId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventOngoing));

