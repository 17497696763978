import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UtilitiesTournaments = ({ onSearch, translation }) => {
    const navigate = useNavigate();

    return (
        <div className="col-12 mb-3 d-flex justify-items-left event-actions">
            <input
                className='input-event-actions form-control'
                placeholder={translation.search}
                onChange={(e) => onSearch(e.target.value)}
            />
            {/* <button className='btn green text-white ms-2' style={{pointerEvents: 'none'}}><FaSearchLocation/></button> */}
            <button className='btn green text-white ms-auto' onClick={() => {
                navigate('/create-tournament')
            }}>Create Tournament
            </button>
        </div>
    );
};

const mapStateToProps = state => ({
    tournaments: state.eventsState.tournaments,
    tournamentsLoading: state.eventsState.tournamentsLoading,
    translation: state.appState.translation,

});

export default connect(mapStateToProps, null)(React.memo(UtilitiesTournaments));


