import React, { useRef, useEffect } from 'react';

const Tabs = ({ selectedTab, tabOptions, setSelectedTab }) => {
  const tabRefs = useRef([]);

  useEffect(() => {
    // Scroll the selected tab into view when selected
    if (tabRefs.current[selectedTab]) {
      tabRefs.current[selectedTab].scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [selectedTab]);

  return (
    <div className='tabs-wrapper d-inline-flex p-0 border-color-blue border-3'>
      {tabOptions.map((item, idx) => (
        <div
          key={`tab${idx}`}
          ref={(el) => (tabRefs.current[idx] = el)}
          className={`tab text-nowrap px-3 pt-1 fw-bold ms-1${idx !== selectedTab ? ' color-blue bg-light' : ' blue text-white'}`}
          role="button"
          onClick={() => setSelectedTab(idx)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
