import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendClubInvitation } from '../actions/clubActions';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';

const SendClubInvitation = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const club = useSelector(state => state.myClubStateReducer.club);

    useEffect(() => {
        if(!club) navigate('/');
    }, [, club, navigate]);

    const handleInvite = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        dispatch(sendClubInvitation(club.id, email))
            .then((successMessage) => {
                toast.success(successMessage)
                navigate('/club');
            })
            .catch((errorMessage) => {
                toast.error(errorMessage)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={`page-container container-fluid`}>
                    <h5 className='text-center mb-4'>Invite New Member to {club?.club_name}</h5>
                    <div className="page-container container-fluid">
                        <div className='row g-3 bg-white rounded'>
                            <div className="club-info col d-flex flex-column">
                                <button className='btn btn-light-green align-self-end mb-5 max-w-20' onClick={() => navigate('/club')}>Back to Club</button>
                                <form onSubmit={handleInvite} className="invite-form">
                                    <div className="form-group">
                                        <label htmlFor="memberEmail" className="form-label">Member's Email:</label>
                                        <input
                                            type="email"
                                            className="form-control max-w-20"
                                            id="memberEmail"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter member's email"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn green text-white mt-2" disabled={isLoading}>Send Invitation</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SendClubInvitation;
