import React from 'react';

const Checkbox = ({ name, label, checked, onChange, className }) => {
  return (
    <div className="form-check">
      <input
        type="checkbox"
        className={`form-check-input ${className}`}
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
