import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearMessage, clearNotification } from '../actions/notificationState';
import Notification from './Notification';
import Message from './Message';

const NotificationManager = ({translation, notificationQueue, clearNotification, messageQueue, clearMessage}) => {
  
    const renderNotification = (notification) => (
        <Notification notification={notification} clearNotification={() => clearNotification(notification.id)} key={notification.id} />
    )

    const renderMessage = ({message, notification_id}) => {
        return <Message message={message} key={notification_id} clearMessage={() => clearMessage(notification_id)} />
    }

    return (
        <>
            <div className={`position-fixed notification-wrapper end-0 mt-2 pt-0 `}>
                {notificationQueue.map(renderNotification)}
                {messageQueue.map(renderMessage)}
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    notificationQueue: state.notification.notificationQueue,
    messageQueue: state.notification.messageQueue,
    translation: state.appState.translation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearNotification: (messageIndex) => dispatch(clearNotification(messageIndex)),
    clearMessage: (messageIndex) => dispatch(clearMessage(messageIndex))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NotificationManager))