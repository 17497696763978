export const formatDate = (date) => {
  const dateObject = new Date(date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
    const userLocale = navigator.language;
  return dateObject.toLocaleString(userLocale, options);
}

export const formatDateOnly = (date) => {
  const dateObject = new Date(date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false
  };
    const userLocale = navigator.language;
  return dateObject.toLocaleString(userLocale, options);
}