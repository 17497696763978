import {
    LOAD_MY_CATCH_MAP_DATA,
    LOADED_MY_CATCH_MAP_DATA,
    LOADED_MY_CATCH_MAP_DATA_FAIL,
} from './types';
import axios from 'axios';

export const loadMyCatchMapData = (eventId = 177) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_MY_CATCH_MAP_DATA
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/my-event-catch-map/`, {
                params: {event_id: eventId},
                headers: config.headers
            });

            dispatch({
                type: LOADED_MY_CATCH_MAP_DATA,
                payload: res.data.results
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                dispatch({
                    type: LOADED_MY_CATCH_MAP_DATA_FAIL,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: LOADED_MY_CATCH_MAP_DATA_FAIL,
                    payload: 'An unknown error occurred'
                });
            }
        }
    }
}

export const loadAllCatchMap = (eventId) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_MY_CATCH_MAP_DATA
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/event-chatch-map/${eventId}/`, {
                params: {event_id: eventId},
                headers: config.headers
            });

            dispatch({
                type: LOADED_MY_CATCH_MAP_DATA,
                payload: res.data.results
            });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                dispatch({
                    type: LOADED_MY_CATCH_MAP_DATA_FAIL,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: LOADED_MY_CATCH_MAP_DATA_FAIL,
                    payload: 'An unknown error occurred'
                });
            }
        }
    }
}