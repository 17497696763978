import React, { useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';

const UploadFish = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

     const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };
    const formData = new FormData();
    formData.append('photo',selectedFile);
    formData.append('event',177);
    formData.append('fish',3);
    formData.append('fish_length',35);
    

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/upload-photo/`, formData, config);
        alert('success')

    } catch (err) {
                alert('fail')

    }
    // UPLOAD HERE
    if (selectedFile) {
      console.log('File selected:', selectedFile);
    } else {
      console.error('No file selected');
    }
  };

  return (
    <>
      <h1>Upload fish</h1>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="fileInput">Select a picture:</label>
        <input
          type="file"
          id="fileInput"
          accept="image/*" // Specify accepted file types (e.g., images)
          onChange={handleFileChange}
        />
        <button type="submit">Upload</button>
      </form>
    </>
  );
};

export default UploadFish;