import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postEvent } from '../actions/eventsState';
import { load_validators } from '../actions/utilsState';
import Input from './../components/Input';
import Textarea from '../components/Textarea';
import Dropdown from '../components/Dropdown';
import DateTimePicker from '../components/DateTimePicker';
import { eventForm } from './../constants/forms';
import MultiDropdown from '../components/MultiDropdown';
import { useNavigate } from 'react-router-dom';
import DropdownFishValidators from '../components/DropdownFishValidators';
import { toast } from 'react-toastify';
import DropdownFishingSpots from '../components/DropdownFishingSpots';
import DropdownCity from '../components/DropdownCity';

const initialStateForm = {
    event_name: '',
    event_logo: '',
    details: '',
    event_location: '',
    event_location_details: '',
    event_participation_type: 'I',
    event_type: 'S',
    start_date: new Date(),
    event_duration_hours: '',
    event_location_country: 1,
    event_location_city: '',
    participation_tax: "10.00",     
    participation_tax_currency : 1,
    principal_sponsors: [],     
    other_sponsors: [],
    validator: "",    
  }

const CreateEventStepOne = ({step, translation, postEvent, sponsors, validators, createLoading, load_validators, fishingspots, locations}) => {
  const navigate = useNavigate();
  const [dirtyForm, setDirtyForm] = useState(false);
  const [stepOneData, setstepOneData] = useState(initialStateForm);

  useEffect(() => {
    load_validators();
  }, [load_validators]);

  useEffect(() => {
    if (step === 2) {
      navigate('/create-fishscoring');
    }
  }, [step, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setstepOneData({
      ...stepOneData,
      [name]: value,
    });
    setDirtyForm(true);
  };

  const handleSponsorChange = (e) => {
    const { name, value } = e.target;
    let __sponsor = [...stepOneData[name]];

    if(__sponsor.includes(value)) {
      __sponsor = __sponsor.filter((item) => item !== value)
      setstepOneData({
        ...stepOneData,
        [name]: __sponsor,
      });
    } else {
      __sponsor.push(value);
      setstepOneData({
        ...stepOneData,
        [name]: __sponsor,
      });
    }
    setDirtyForm(true);
  };

  const handleDateChange = (date) => {
    setstepOneData({
      ...stepOneData,
      start_date: new Date(date),
    });
    setDirtyForm(true);
  };

  const handleBannerUpload = (event) => {
    setstepOneData({
      ...stepOneData,
      event_logo: event.target.files[0],
    });
    setDirtyForm(true);
  }

  const handleValidatorChange = (event) => {
    setstepOneData({
      ...stepOneData,
      validator: event.target.value,
    });
    setDirtyForm(true);
  }

  const nextStep = (e) => {
    e.preventDefault();
    if(validateMandatory(stepOneData)) {
      const formData = new FormData();
      const formDataKeys = Object.keys(stepOneData);
      
      for(let i = 0; i < formDataKeys.length; i++) {
        formData.append(formDataKeys[i], stepOneData[formDataKeys[i]]);
      }
      formData.append('start_date', new Date(stepOneData.start_date).toISOString());

      postEvent(formData);
    } else {
      toast.error('Please complete all mandatory fields');
    }
  }

  const validateMandatory = (data) => {
    let validData = true;
    const arrayObjectKeys = Object.keys(initialStateForm);

    for(let k = 0; k < arrayObjectKeys.length; k++) {
      if(!data[arrayObjectKeys[k]] && arrayObjectKeys[k] !== 'tournament') {
        validData = false;
      }
    }

    return validData;
  }

  const renderForm = () => Object.keys(eventForm).map((key, index) => {
    switch(eventForm[key].type) {
        case 'input':
          return (
            <Input
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              placeholder={translation[eventForm[key].placeholder]}
              value={stepOneData[key] || ''}
              onChange={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'number':
          return (
            <Input
              type="number"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              placeholder={translation[eventForm[key].placeholder]}
              value={stepOneData[key] || ''}
              onChange={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'textarea':
          return (
            <Textarea
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              value={stepOneData[key] || ''}
              onChange={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'datetime':
          return (
            <DateTimePicker
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              value={stepOneData[key] || ''}
              onChange={handleDateChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown':
          return (
            <Dropdown
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={eventForm[key].options}
              selectedOption={stepOneData[key] || ''}
              onSelect={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_sponsor':
          return (
            <MultiDropdown
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={sponsors}
              selectedOptions={stepOneData[eventForm[key].label]}
              onSelect={handleSponsorChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'upload_banner':
          return (
            <Input
              type="file"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={sponsors}
              selectedOptions={stepOneData[eventForm[key].label]}
              onChange={handleBannerUpload}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_validator':
          return (
            <DropdownFishValidators
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={validators || []}
              selectedOptions={stepOneData[eventForm[key].label]}
              onSelect={handleValidatorChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_fishingspots':
          return (
            <DropdownFishingSpots
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={fishingspots}
              selectedOptions={stepOneData[eventForm[key].label]}
              onSelect={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_city':
          return (
            <DropdownCity
              type="text"
              className="form-control"
              name={eventForm[key].label}
              label={translation[eventForm[key].label]}
              options={locations ? locations[0]?.cities : []}
              selectedOptions={stepOneData[eventForm[key].label]}
              onSelect={handleInputChange}
              key={key + index}
              formAttr={key}
            />
          );
        default:
            return null
    }
  })

  return (<>
    <div className={`page-container container-fluid`}>
      <form className='row g-3 bg-white rounded p-3' onSubmit={nextStep}>
        <div className='steps-container d-flex justify-content-around mb-3'>
          <div className={`fw-bold color-red`}><span>General</span></div>
          <div className={`fw-bold `}><span>Scoring</span></div>
          <div className={`fw-bold`}><span>Prizes</span></div>
        </div>
        {/* <div className='info-container text-end color-green'>
          {`Creating single event`}
        </div> */}
        <div className={`step`}>
          {renderForm()}
        </div>
        <button type="submit" className="btn blue text-white ms-auto submit-button" disabled={createLoading}>
          {translation.next_step}
        </button>
      </form>
    </div>
  </>
    
  );
};

const mapStateToProps = state => ({
    step: state.eventsState.step,
    translation: state.appState.translation,
    sponsors: state.utilsState.sponsors,
    validators: state.utilsState.validators,
    fishingspots: state.utilsState.fishingspots,
    locations: state.utilsState.locations,
    fish_types: state.utilsState.fish_types,
    submitedEvent: state.eventsState.submitedEvent,
    createLoading: state.eventsState.createLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postEvent: (stepOneData) => dispatch(postEvent(stepOneData)),
    load_validators: () => dispatch(load_validators()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepOne))