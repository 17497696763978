import React, { useState } from 'react';
import { connect } from 'react-redux';

const FishScoringTable = ({ fishScoring, fish_types, translation }) => {
    const [fishScoringXCatch] = useState(fishScoring[0]?.top_x_catches);

    const renderRow = (item, key) => {
        const fishType = fish_types.find((fish) => fish.id === item.fish);

        return (
            <tr key={key}>
                <th scope="row">{key + 1}</th>
                <td>{fishType.name} ({fishType.scientific_name})</td>
                {item.scoring_type !== 'Top_X_Catches' ? <td>{item.accountable_catch_slots}</td> : null}
                <td>{item.accountable_min_length} cm</td>
                <td>{item.under_min_length_points}</td>
            </tr>
        );
    }

    return (
        <>
            <div className='col-12 d-flex m-0 mt-3 flex-column justify-items-center'>
                <p className='color-galben text-end'>*{fishScoringXCatch ? `Puncteaza primii ${fishScoringXCatch} pesti din speciile din tabel.` : 'Puncteaza sloturile conform tabelului de mai jos.'}</p>
                <table className="table table-hover w-100">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{translation.fish}</th>
                            {fishScoring[0].scoring_type !== 'Top_X_Catches' ? <th scope="col">{translation.catch_slots}</th> : null}
                            <th scope="col">{translation.min_length}</th>
                            <th scope="col">{translation.under_length_points}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fishScoring.map((item, index) => renderRow(item, index))}
                    </tbody>
                </table>
            </div>
        </>
    );
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(FishScoringTable);