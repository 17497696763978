import {
    POST_MESSAGE,
    CLEAR_MESSAGE,
    CLEAR_SOCKET
} from './types';

export const postMessage = (message) => async dispatch => {
    dispatch({
        type: POST_MESSAGE,
        payload: message
    });
};

export const clearNotification = (id) => async dispatch => {
     dispatch({
        type: CLEAR_MESSAGE,
        payload: id
    });
};

export const clearMessage = (id) => async dispatch => {
     dispatch({
        type: CLEAR_SOCKET,
        payload: id
    });
};