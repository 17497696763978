import {
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_FAIL,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAIL
} from '../actions/clubActions';

const initialState = {
    invitationAccepted: false,
    invitationSent: false,
    error: null,
    message: null
};

export default function clubInvitation(state = initialState, action) {
    switch (action.type) {
        case ACCEPT_INVITATION_SUCCESS:
            return {
                ...state,
                invitationAccepted: true,
                error: null
            };
        case ACCEPT_INVITATION_FAIL:
            return {
                ...state,
                invitationAccepted: false,
                error: action.payload
            };
        case SEND_INVITATION_SUCCESS:
            return {
                ...state,
                error: null,
                invitationSent: true,
            };
        case SEND_INVITATION_FAIL:
            return {
                ...state,
                error: action.payload,
                invitationSent: false,
            };
        default:
            return state;
    }
}
