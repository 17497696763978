import { useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import getAxiosConfig from '../utils/axiosConfig';
import axios from 'axios';
import { IoCheckmark, IoCheckmarkDoneSharp, IoCloseCircleSharp, IoEye } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import DropdownFishTypes from './DropdownFishTypes';

const RevalidateTable = ({ enrollments, event, translation, fish_types, refreshList }) => {
    const { eventId } = useParams();
    const [userQuery, setUserQuery] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [captureModal, setCaptureModal] = useState(null);
    const [selectedFish, setSelectedFish] = useState(null);
    const [selectedFishType, setSelectedFishType] = useState(null);
    const [selectedFishLength, setSelectedFishLength] = useState(null);

    useEffect(() => {
        if(userQuery) {
            const fetchData = async () => {
                const config = getAxiosConfig();
                try {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/user-captures/${eventId}/${userQuery.id}/`, config);
                    setMapData(res.data.results);
                } catch (err) {
                    console.log(err);
                }
            }
           fetchData(); 
        }
    }, [userQuery, event]);

    const updateCapture = async (item, status) => {
        try {
            const config = getAxiosConfig();

            const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event/captures-validate/${item.id}/`, {
                fish_length: selectedFishLength,
                is_approved: status,
                fish: selectedFishType,
            }, config);
            toast.success('Fish updated successfully!');
            refreshList();
            setCaptureModal(null);

        } catch(err) {
            toast.error('Error while updating fish!');
            setCaptureModal(null);

        }
    }


    return (
        <>
            <DropdownButton
                as={ButtonGroup}
                title={userQuery?.value || `Alege utilizator`}
            >
                {enrollments?.map(score => <Dropdown.Item active={userQuery?.id === score.user} onClick={() => setUserQuery({id: score.user, value: score.user_full_name})} key={score.user_full_name}>{score.user_full_name}</Dropdown.Item>)}
                
            </DropdownButton>
            <table className='table table-hover table-catch w-100 mt-3'>
                <thead>
                    <tr>
                        <th className="galben text-white">Fish</th>
                        <th className="galben text-white">Length</th>
                        <th className="galben text-white">Status</th>
                        <th className="galben text-white"></th>
                    </tr>
                </thead>
                <tbody>
                    { mapData?.map((item, key) => {
                        return (
                            <tr key={key}>
                                <td className="position-relative">
                                    {fish_types.find(el => el.id === item.fish).name}
                                    {/* <span className='position-absolute catch-hour'>
                                        {new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() : `0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() : `0${new Date(item.upload_date).getMinutes()}`}</span>     */}
                                </td>
                                <td className="">{item.fish_length}</td>
                                <td className="">{item.is_approved ? <IoCheckmarkDoneSharp className='color-green' /> : item.is_rejected ? <IoCloseCircleSharp className='color-red' />  : <IoCheckmark className="color-galben" />}</td>
                                <td className="">
                                    <IoEye role="button" onClick={() => {setCaptureModal(item);setSelectedFishLength(item.fish_length);setSelectedFishType(item.fish);}} />
                                </td>
                            </tr>
                        );
                    }) }
                </tbody>
                
            </table>
            <Modal show={captureModal} onHide={() => setCaptureModal(null)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Captura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {captureModal && (
                        <>
                            <div className='d-flex'>
                                <button className='btn red text-white float-start m-0' onClick={() => updateCapture(captureModal, false)}>Reject</button>
                                <button className='ms-auto btn blue text-white' onClick={() => updateCapture(captureModal, true)}>Approve</button>
                            </div>
                            <Input
                                type="text"
                                className="form-control text-start my-3"
                                name={translation.no_player}
                                label={translation.no_player}
                                value={captureModal?.user_name}
                                disabled
                                readOnly
                            />
                            <DropdownFishTypes
                                type="text"
                                className="form-control text-start my-3"
                                name={translation.fish_type}
                                label={translation.fish_type}
                                options={fish_types}
                                selectedOptions={selectedFishType}
                                onSelect={(e) => setSelectedFishType(e.target.value)}
                            />
                            <Input
                                type="number"
                                className="form-control step my-3"
                                name={`Fish Length`}
                                label={translation.ong_fish_length}
                                placeholder={translation.ong_fish_length}
                                onChange={(e) => setSelectedFishLength(e.target.value)}
                                value={selectedFishLength}
                            />
                            <img className='w-100' src={captureModal?.photo} alt=""/>                         
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
    fish_types: state.utilsState.fish_types,
});

export default connect(mapStateToProps, null)(RevalidateTable);