import React, { useEffect, useState } from 'react';
import Input from '../components/Input';
import DropdownFishTypes from '../components/DropdownFishTypes';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoTrashBin, IoAddCircleOutline } from "react-icons/io5";
import { postFishScoring } from '../actions/eventsState';
import { eventFishScoringForm, eventFishScoringTopCatches } from '../constants/forms';
import { Button, ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';

const initialStateForm = {
  event: null,
  fish: null,
  accountable_catch_slots: 1,
  accountable_min_length: 0,
  under_min_length_points: 0,
}

const CreateEventStepTwo = ({createdEvent, translation, postFishScoring, fish_types, step, createLoading}) => {
  const navigate = useNavigate();
  const [dirtyForm, setDirtyForm] = useState(false);
  const [stepTwoData, setstepTwoData] = useState([{...initialStateForm, event: createdEvent?.id}]);
  const [formData, setFormData] = useState(eventFishScoringForm);
  const [isTopX, setIsTopX] = useState(false);
  const [topX, setTopX] = useState(null);

  useEffect(() => {
    if(!createdEvent) {
      navigate('/dashaboard');
    }
  });

  useEffect(() => {
    if (step === 3) {
      navigate('/create-prizes')
    }
  }, [step]);

  useEffect(() => {
    setFormData(isTopX ? eventFishScoringTopCatches : eventFishScoringForm);
  }, [isTopX])

  const handleChange = (e, indexScore) => {
    const { name, value } = e.target;
    const stateData = [...stepTwoData];

    stateData[indexScore][name] = value;
    setstepTwoData([...stateData]);
    setDirtyForm(true);
  };

  const addScoring = (e) => {
    e.preventDefault();
    const stateData = [...stepTwoData];
    stateData.push({...initialStateForm, event: createdEvent.id});

    setstepTwoData([...stateData]);
    setDirtyForm(true);
  };

  const deleteScoring = (e, indexScore) => {
    e.preventDefault();
    const stateData = [...stepTwoData];

    if(stateData.length === 1) {
      return;
    }

    stateData.splice(indexScore, 1);

    setstepTwoData([...stateData]);
    setDirtyForm(true);
  } 

  const nextStep = (e) => {
    e.preventDefault();
    if(validateMandatory(stepTwoData) && validateTopX()) {
      const arrayObjectKeys = Object.keys(initialStateForm);
      let data = [...stepTwoData];

      for(let i = 0; i < data.length; i++) {
        for(let k = 0; k < arrayObjectKeys.length; k++) {
          if(!(typeof data[i][arrayObjectKeys[k]] === 'number')) {
            data[i][arrayObjectKeys[k]] = parseInt(data[i][arrayObjectKeys[k]]);
          }
        }
      }

      for(let i = 0; i < data.length; i++) {
        data[i].scoring_type = isTopX ? 'Top_X_Catches' : 'Type_1';
        data[i].top_x_catches = topX;
      }

      postFishScoring(data);
    } else {
      toast.error('Please complete all mandatory fields');
    }
  }

  const validateMandatory = (data) => {
    let validData = true;
    const arrayObjectKeys = Object.keys(initialStateForm);

    for(let i = 0; i < data.length; i++) {
      for(let k = 0; k < arrayObjectKeys.length; k++) {
        if(!data[i][arrayObjectKeys[k]]) {
          validData = false;
        }
      }
    }

    return validData;
  }

  const validateTopX = () => isTopX ? topX : true;

  const renderForm = (indexScore) => Object.keys(formData).map((key, index) => {
    switch(formData[key].type) {
        case 'number':
          return (
            <Input
              type="number"
              className="form-control"
              name={formData[key].label}
              label={translation[formData[key].label]}
              placeholder={translation[formData[key].placeholder]}
              value={stepTwoData[indexScore][key] || ''}
              onChange={(e) => handleChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        case 'dropdown_fishtypes':
          return (
            <DropdownFishTypes
              type="text"
              className="form-control text-start"
              name={formData[key].label}
              label={translation[formData[key].label]}
              options={fish_types}
              selectedOptions={stepTwoData[indexScore][formData[key].label]}
              onSelect={(e) => handleChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        default:
            return null
    }
  });

  return (<>
    <div className={`page-container d-flex flex-column align-self-stretch overflow-y-auto`}>
      <form className='bg-white rounded p-3 container' onSubmit={nextStep}>
        <div className='steps-container d-flex justify-content-around mb-3'>
          <div className={`fw-bold color-green`}><span>General</span></div>
          <div className={`fw-bold  color-red `}><span>Scoring</span></div>
          <div className={`fw-bold`}><span>Prizes</span></div>
        </div>
        <div className='info-container text-end color-green'>
          {`Creating single event`}
        </div>
        <div className={`step`}>
            <ButtonGroup className='d-flex mb-3'>
              <Button
                color={'warning'}
                onClick={() => setIsTopX(false)}
                active={!isTopX}
                className='btn-light-blue'
              >
                {translation.scoring_slots}
              </Button>
              <Button
              color={'warning'}
                onClick={() => setIsTopX(true)}
                active={isTopX}
                className='btn-light-blue'
              >
                {translation.topxcatches}
              </Button>
            </ButtonGroup>
            {isTopX && <div className=''>
              <Input
                type="number"
                className="form-control"
                name={'topx'}
                label={translation.topx}
                placeholder={translation.topx}
                value={topX}
                onChange={(e) => setTopX(e.target.value)}
              />
            </div>}
            {stepTwoData.map((_item, indexScore) => (
              <div className='scoring-container d-flex flex-column'>
                {renderForm(indexScore)}
                <button type="submit" className="btn red text-white submit-button align-self-end" onClick={(e) => deleteScoring(e, indexScore)}>
                  <IoTrashBin className='ms-2' /> {translation.deleteScoring}
                </button>
              </div>
            ))}
          </div>        

        <div className='row justify-content-end'>
              <button type="submit" className="btn green text-white submit-button  mt-4" onClick={(e) => addScoring(e)}>
                <IoAddCircleOutline /> {translation.addScoring}
              </button>
              <button type="submit" className="btn blue text-white submit-button mt-4 ms-4" disabled={createLoading}>
                {translation.next_step}
              </button>
        </div>
        
      </form>
    </div>
  </>
    
  );
};

const mapStateToProps = state => ({
    createdEvent: state.eventsState.createdEvent,
    menuOpen: state.appState.menuOpen,
    translation: state.appState.translation,
    fish_types: state.utilsState.fish_types,
    step: state.eventsState.step,
    createLoading: state.eventsState.createLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postFishScoring: (stepTwoData) => dispatch(postFishScoring(stepTwoData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepTwo))