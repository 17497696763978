import {
    LOAD_MY_CLUB,
    LOADED_MY_CLUB,
    LOADED_MY_CLUB_FAIL
} from '../actions/types';

const initialState = {
    loading: false,
    club: null,
    is_club_owner: null,
    error: null
};

export default function myClubStateReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MY_CLUB:
            return {
                ...state,
                loading: true,
                error: null
            };

        case LOADED_MY_CLUB:
            const clubData = action.payload.clubData[0].club;

            return {
                ...state,
                loading: false,
                club: clubData,
                is_club_owner: clubData.club_owner === action.payload.authUserId,
                error: null

            };

        case LOADED_MY_CLUB_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};
