import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoTrashBin } from "react-icons/io5";
import { postPrizes } from '../actions/eventsState';
import Input from '../components/Input';
import { eventPrizes } from '../constants/forms';

const initialStateForm = {
  event: null,
  prize_name: '',
  prize_value: ''
}

const CreateEventStepThree = ({createdEvent, translation, postPrizes, createLoading, step}) => {
  const navigate = useNavigate();
  const [dirtyForm, setDirtyForm] = useState(false);
  const [stepThreeData, setstepThreeData] = useState([{...initialStateForm, event: createdEvent?.id}]);

  useEffect(() => {
    if(!createdEvent) {
      navigate('/dashboard');
    }
  });

  useEffect(() => {
    if (step === 4) {
      navigate('/success')
    }
  }, [step, navigate]);

  const handleChange = (e, indexScore) => {
    const { name, value } = e.target;
    const stateData = [...stepThreeData];
    stateData[indexScore][name] = value;
    setstepThreeData([...stateData]);
    setDirtyForm(true);
  };

  const handleNumberChange = (e, indexScore) => {
    const { name, value } = e.target;
    const stateData = [...stepThreeData];
    stateData[indexScore][name] = parseInt(value, 10);
    setstepThreeData([...stateData]);
    setDirtyForm(true);
  };

  const addPrizeSection = (e) => {
    e.preventDefault();
    const stateData = [...stepThreeData];
    stateData.push({...initialStateForm, event: createdEvent.id});

    setstepThreeData([...stateData]);
    setDirtyForm(true);
  };

  const deletePrizeSection = (e, indexScore) => {
    e.preventDefault();
    const stateData = [...stepThreeData];
    if(stateData.length === 1) {
      return;
    }

    stateData.splice(indexScore, 1);

    setstepThreeData([...stateData]);
    setDirtyForm(true);
  } 

  const nextStep = (e) => {
    e.preventDefault();
    validate(stepThreeData);
    postPrizes(stepThreeData);
  }

  const validate = (data) => {
    let validData = true;
    const arrayObjectKeys = Object.keys(initialStateForm);

    for(let i = 0; i < data.length; i++) {
      for(let k = 0; k < arrayObjectKeys.length; k++) {
        if(!data[i][arrayObjectKeys[k]]) {
          validData = false;
        }
      }
    }

    return validData;
  }

  const previousStep = (e) => {
    e.preventDefault();
  }

  const renderForm = (indexScore) => Object.keys(eventPrizes).map((key, index) => {
    switch(eventPrizes[key].type) {
        case 'number':
          return (
            <Input
              type="number"
              className="form-control"
              name={eventPrizes[key].label}
              label={translation[eventPrizes[key].label]}
              placeholder={translation[eventPrizes[key].placeholder]}
              value={stepThreeData[indexScore][key] || ''}
              onChange={(e) => handleNumberChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        case 'string':
          return (
            <Input
              type="string"
              className="form-control"
              name={eventPrizes[key].label}
              label={translation[eventPrizes[key].label]}
              placeholder={translation[eventPrizes[key].placeholder]}
              value={stepThreeData[indexScore][key] || ''}
              onChange={(e) => handleChange(e, indexScore)}
              key={key + index}
              formAttr={key}
            />
          );
        default:
            return null
    }
  });

  return (<>
    
    <div className={`page-container container-fluid`}>
      <form className='row g-3 bg-white rounded p-4 container' onSubmit={nextStep}>
        <div className='steps-container d-flex justify-content-around mb-3'>
          <div className={`fw-bold color-green`}><span>General</span></div>
          <div className={`fw-bold  color-red `}><span>Scoring</span></div>
          <div className={`fw-bold`}><span>Prizes</span></div>
        </div>
        <div className='info-container text-end color-green'>
          {`Creating single event`}
        </div>
        <div className={`step`}>
          
            {stepThreeData.map((_item, indexScore) => (
              <div className='scoring-container d-flex flex-column'>
                {renderForm(indexScore)}
                <button type="submit" className="btn red text-white submit-button align-self-end" onClick={(e) => deletePrizeSection(e, indexScore)}>
                  <IoTrashBin className='ms-2' /> {translation.deletePrizeSection}
                </button>
              </div>
            ))}
          </div>        

        <div className='row justify-content-end'>
              <button type="submit" className="btn green text-white submit-button  mt-4" onClick={(e) => addPrizeSection(e)}>
                {translation.addPrizeSection}
              </button>
              <button type="submit" className="btn blue text-white submit-button mt-4 ms-4" disabled={createLoading}>
                {translation.next_step}
              </button>
        </div>
        
      </form>
    </div>
  </>
  );
};

const mapStateToProps = state => ({
    createdEvent: state.eventsState.createdEvent,
    step: state.eventsState.step,
    createLoading: state.eventsState.createLoading,
    menuOpen: state.appState.menuOpen,
    translation: state.appState.translation,
    fish_types: state.utilsState.fish_types,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postPrizes: (stepThreeData) => dispatch(postPrizes(stepThreeData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepThree))