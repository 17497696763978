import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { load_sponsors } from "../actions/utilsState";
import SponsorsCard from "../components/SponsorsCard";
import Header from "../components/Header";
import Loader from "../components/Loader";

const SponsorsPage = ({ load_sponsors, sponsors, isLoading, isError }) => {

    useEffect(() => {
        load_sponsors();
    }, [load_sponsors]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error loading sponsors.</div>;
    }

    return (
        <>
            <div className={`page-container container-fluid`}>
                <h1 className='text-center mb-4'>Sponsors</h1>
                <div className='row g-3'>
                    {sponsors && sponsors.length > 0 ? (
                        sponsors.map(sponsor => (
                            <SponsorsCard key={sponsor.id} sponsor={sponsor} />
                        ))
                    ) : (
                        <h2>No sponsors available</h2>
                    )}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    sponsors: state.utilsState.sponsors,
    isLoading: state.utilsState.isLoading,
    isError: state.utilsState.isError,
});

const mapDispatchToProps = dispatch => ({
    load_sponsors: () => dispatch(load_sponsors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsPage);