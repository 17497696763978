import React from 'react';
import { IoFishSharp } from "react-icons/io5";

const Empty = ({ label }) => (
    <div className={`d-flex justify-content-center align-items-center`}>
        <IoFishSharp className='fs-1 color-blue mx-3'/> <span className='fs-3'>{label}</span>
    </div>
);

export default Empty;

