import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadMyEvents, selectEvent } from '../actions/myEventsState';
import Loader from '../components/Loader';
import EventCard from '../components/EventCard';

const MyEvents = ({ events, myEventsLoading, loadMyEvents, translation }) => {

    useEffect(() => {
        if (!events.length) {
            loadMyEvents();
        }
    }, [loadMyEvents]);

    const renderEventCategory = useCallback(() => (
        <div className='mb-5'>
            <div className='row g-3'>
                {events.map((item, index) => (
                    <EventCard event={item} key={`event_${index}`} />
                ))}
            </div>
        </div>
    ), [events]);

    return (
        <>
            {myEventsLoading ? (
                <Loader />
                ) : (
                <div className={`page-container container-fluid`}>
                    <h1 className='text-center mb-4'>My Organized Events</h1>
                    <div className='row g-3'>
                        {renderEventCategory()}
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    events: state.myEventsStateReducer.events,
    myEventsLoading: state.myEventsStateReducer.loading,
    translation: state.appState.translation
});

const mapDispatchToProps = { loadMyEvents, selectEvent };

export default connect(mapStateToProps, mapDispatchToProps)(MyEvents);