import React from 'react';

const SponsorsCard = ({ sponsor, index }) => {
  const handleClick = () => {
    window.open(sponsor.website, "_blank");
  };

  return (
    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex m-0 mt-3 flex-column justify-content-center align-items-center"
         key={`event_${index}`}
         onClick={handleClick}
    >
      <div className="w-100 sponsor-card flex-grow-1 d-flex justify-content-center align-items-center">
        <img className="sponsor-logo" src={sponsor.logo} alt="sponsor_logo"/>
      </div>
      {/*<p className="text-center fw-bold mt-auto pt-2 sponsor-name">{sponsor.name}</p>*/}
    </div>
  );
}

export default React.memo(SponsorsCard);
