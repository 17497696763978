import {
    CLEAR_MESSAGE,
    POST_MESSAGE,
    CLEAR_SOCKET,
    SOCKET_MESSAGE
} from '../actions/types';


const initialState = {
    notificationQueue: [],
    messageQueue: [],
};

function notificationReducer(state = initialState, action) {
    const { type, payload } = action;
    let notificationQueue = [...state.notificationQueue];
    let messageQueue = [...state.messageQueue];

    switch(type) {
        case POST_MESSAGE:
            notificationQueue.push({...payload, id: new Date().getTime()})

            return {
                ...state,
                notificationQueue: notificationQueue
            }
        case CLEAR_MESSAGE:
            if(notificationQueue.length === 1) {
                notificationQueue = [];
            } else {
                const clearMessageIndex = notificationQueue.findIndex((item) => item.id === payload);
                notificationQueue.splice(clearMessageIndex, 1);
            }

            return {
                ...state,
                notificationQueue: notificationQueue
            }
        case CLEAR_SOCKET:
            if(messageQueue.length === 1) {
                messageQueue = [];
            } else {
                const clearMessageIndex = messageQueue.findIndex((item) => item.notification_id === payload);
                messageQueue.splice(clearMessageIndex, 1);
            }

            return {
                ...state,
                messageQueue: messageQueue
            }
        case SOCKET_MESSAGE:
            messageQueue.push({...payload})

            return {
                ...state,
                messageQueue: messageQueue
            }
        default:
            return state
    }
}

export default notificationReducer;