import {
    LOAD_MY_EVENTS,
    LOADED_MY_EVENTS,
    LOADED_MY_EVENTS_FAIL, SELECT_EVENT,
} from './types';
import axios from 'axios';
import getAxiosConfig from '../utils/axiosConfig';

export const loadMyEvents = () => async dispatch => {
    if (localStorage.getItem('access')) {
        try {
            dispatch({
                type: LOAD_MY_EVENTS
            });
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/organized-events/`, getAxiosConfig());
            dispatch({
                type: LOADED_MY_EVENTS,
                payload: res.data.events
            });
        } catch (err) {
            dispatch({
                type: LOADED_MY_EVENTS_FAIL,
            });
        }
    } else {
        dispatch({
            type: LOADED_MY_EVENTS_FAIL
        });
    }
};


export const selectEvent = (event) => async dispatch => {
    dispatch({
        type: SELECT_EVENT,
        payload: event
    });
};
