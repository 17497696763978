import axios from 'axios';
import getAxiosConfig from '../utils/axiosConfig';
import {
    SEND_MESSAGE_START,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAIL,
    MESSAGE_THROTTLED,
    RESET_CONTACT_FORM
} from './types';

export const sendMessage = (messageData, recaptcha = null) => async (dispatch) => {
    dispatch({ type: SEND_MESSAGE_START });

    try {
        const requestData = {
            ...messageData,
            ...(recaptcha && { recaptcha })
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/site/contact/`,
            requestData,
            getAxiosConfig()
        );

        dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: response.data.message
        });
    } catch (error) {
        let errorMessage = 'Error sending message';
        if (error.response && error.response.data) {
            if (error.response.status === 429) {
                errorMessage = "You have exceeded the number of allowed messages. Please try again later.";
                dispatch({
                    type: MESSAGE_THROTTLED,
                    payload: errorMessage
                });
            } else {
                errorMessage = Object.values(error.response.data).join(' ') || errorMessage;
                dispatch({
                    type: SEND_MESSAGE_FAIL,
                    payload: errorMessage
                });
            }
        } else {
            dispatch({
                type: SEND_MESSAGE_FAIL,
                payload: errorMessage
            });
        }
    }
};

export const resetContactForm = () => ({
  type: RESET_CONTACT_FORM,
});
