import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EventCard from '../components/EventCard';
import SponsorsCard from '../components/SponsorsCard';


const Tournament = ({ selectedTournament, translation, sponsors }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(!selectedTournament) {
      navigate('/')
    }
  }, []);

  const render = useCallback(() => {
    if(!selectedTournament) {
      return null;
    }

    return (<div className='col-12 d-flex mt-0'>
        <div className='w-100'>
            <p className='fw-bold fs-5'>{selectedTournament['tournament_name']}</p>
            <img className='max-w-20' src={selectedTournament['tournament_banner']} alt="tournament_banner"/>
            <p className='m-0 mb-2'>{translation.details}: {selectedTournament['tournament_details']}</p>
        </div>   
    </div>);
  }, [selectedTournament, translation]);

  const renderEvents = useCallback(() => {
    if(!selectedTournament) {
      return null;
    }
    return selectedTournament.events.map((item, index) => <EventCard event={item} key={index} />);
  }, [selectedTournament]);

  const renderSponsors = useCallback(() => {
    if(!sponsors && !selectedTournament) {
      return null;
    }

    return selectedTournament.principal_sponsors.concat(selectedTournament.other_sponsors).map((item, index) => <SponsorsCard sponsor={item} index={index} key={index} />);
  }, [selectedTournament, sponsors]);

  return (
      <>
        <div className={`page-container container-fluid bg-white`}>
            <div className='row gx-3 g-3'>
              <p className='section-name fw-bold fs-4 m-0 color-green'>
                <span>{translation.tournament}</span>
              </p>
              {render()}
              <p className='section-name fw-bold fs-4 m-0 color-green'>{translation.events}</p>
              {renderEvents()}
              <p className='section-name fw-bold fs-4 m-0 color-green mt-3'>{translation.sponsors}</p>
              {renderSponsors()}
            </div>
        </div>
      </>
  );
}

const mapStateToProps = state => ({
    selectedTournament: state.eventsState.selectedTournament,
    menuOpen: state.appState.menuOpen,
    translation: state.appState.translation,
    sponsors: state.utilsState.sponsors,
});

export default connect(mapStateToProps, null)(React.memo(Tournament));

