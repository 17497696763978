import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.NODE_ENV !== 'production', // this line is optional and ensures that Redux DevTools are only available in development
});

export default store;
