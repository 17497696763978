import React from 'react';

const Input = ({ name, value, onChange, label, type, placeholder, className, ref }) => {  
  return (
        <div className="mb-3">
            <label htmlFor={name} className="form-label">
                { label }:
            </label>
            <input
                type={type}
                accept="image/*"
                className={`form-control ${className}`}
                ref={ref}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default React.memo(Input);


