import {
    LOAD_TRANSLATIONS
} from '../actions/types';
import { translations } from '../constants/translations';

const storedLanguage = localStorage.getItem('language');
const initialState = {
    language: storedLanguage || 'ro',
    translation: translations[storedLanguage || 'ro']
};

function translateReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_TRANSLATIONS:
            return {
                ...state,
                language: payload,
                translation: translations[payload]
            }
        default:
            return state
    }
}

export default translateReducer;