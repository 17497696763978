import axios from "axios";
import { UPLOAD_PROFILE_PHOTO_FAIL,
    UPLOAD_PROFILE_PHOTO_LOADING,
    UPLOAD_PROFILE_PHOTO_SUCCESS,
    UPDATE_USER_PROFILE_STARTING,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL
} from "./types";
import getAxiosConfig from '../utils/axiosConfig';

export const uploadProfilePhoto = (file) => async (dispatch) => {
    const config = getAxiosConfig();
    config.headers['Content-Type'] = 'multipart/form-data';

    if (Object.keys(config).length > 0) {
        const formData = new FormData();
        formData.append('profile_picture', file);

        try {
            dispatch({ type: UPLOAD_PROFILE_PHOTO_LOADING });
            const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/profiles/photo/`, formData, config);
            dispatch({ type: UPLOAD_PROFILE_PHOTO_SUCCESS, payload: res.data });

        } catch (err) {
            dispatch({ type: UPLOAD_PROFILE_PHOTO_FAIL });
        }
    } else {
        dispatch({ type: UPLOAD_PROFILE_PHOTO_FAIL });
    }
};

export const updateUserProfile = (profileData) => async (dispatch) => {
    const config = getAxiosConfig();
    config.headers['Content-Type'] = 'application/json';
    
    try {
        dispatch({ type: UPDATE_USER_PROFILE_STARTING });
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/profiles/userprofile/`, profileData, config);

        dispatch({
            type: UPDATE_USER_PROFILE_SUCCESS,
            payload: res.data });

    } catch (err) {
        const errorMessage = err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err.message;

        dispatch({
            type: UPDATE_USER_PROFILE_FAIL,
            payload: errorMessage,
        });
    }
};