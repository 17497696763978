import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Create a new icon instance with imported images
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

// Set the default icon for all Leaflet markers
L.Marker.prototype.options.icon = DefaultIcon;


// Define custom icon
const customIcon = new L.Icon({
    iconUrl: require('./../containers/icons/map_marker_icon.png'),
    iconRetinaUrl: require('./../containers/icons/map_marker_icon.png'), // Optional for retina display icons
    iconSize: [35, 35], // Size of the icon
    iconAnchor: [12, 35],
    popupAnchor: [1, -44],
    // shadowUrl: require('./icons/map_marker_icon.png'), // Optional shadow image
    shadowSize: [41, 41] // Size of the shadow image
});


const MyEventCatchMap = ({ lat, lng, event_name, fish_name, fish_length, photo_url }) => {
  return (
    <MapContainer center={[lat, lng]} zoom={13} style={{ height: "30vh", width: "100%" }}>
        <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
            position={[lat, lng]}
            icon={customIcon}>
            <Popup>
            Event: {event_name}<br/>
            Fish : {fish_name} [{fish_length}cm] <br/>
            </Popup>
        </Marker>
    </MapContainer>
    );
};


export default MyEventCatchMap;
