import ContactForm  from '../components/ContactForm';
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";

const ContactPage = ({translation}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    return (
    <>
    <div className='login-container mt-2 d-flex justify-content-center'>
        <div className='login-section d-flex rounded justify-content-center flex-column px-1'>
            <div className='d-flex w-100 justify-content-center'>
                <img className='img-responsive w-100 mb-2' src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png' alt='logo'/>
            </div>
            <h1 className='custom-title'>{translation.contact_us_title}</h1>
            <p className='custom-subtitle'>{translation.contact_us_subtitle}</p>
            <ContactForm/>
            { isAuthenticated && (
            <p className='mt-3'>
                <Link to='/sponsors'>{translation.sponsors_link_text}</Link>
            </p>
            )
            }
        </div>
    </div>
    </>
);
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(ContactPage);

