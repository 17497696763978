import React from 'react';
import { IoFishOutline } from "react-icons/io5";

const FishLoader = () => (
    <div className='fishLoader-container d-inline-flex'>
        <IoFishOutline className='fishLoader-icon'/>
        <IoFishOutline className='fishLoader-icon'/>
        <IoFishOutline className='fishLoader-icon'/>
    </div>
);

export default FishLoader;