import React from 'react';
import { IoFishOutline } from "react-icons/io5";

const Loader = () => (
    <div className={`page-container container-fluid d-flex justify-content-center align-items-center`}>
        <div className='fish-container'>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
            <IoFishOutline className='fish-icon'/>
        </div>
    </div>
);

export default Loader;

