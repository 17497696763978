import React from 'react';
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";

const DeleteAccountInstructions = ({translation}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
        <>
            <div className='login-container mt-2 d-flex justify-content-center'>
                <div className='login-section d-flex rounded justify-content-top flex-column px-1'>
                    <div className='d-flex w-100 justify-content-center'>
                        <img
                            className='img-responsive w-50 mb-2'
                            src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png'
                            alt='logo'
                        />
                    </div>
                    <h1 className='custom-title'>{translation.delete_account_title}</h1>
                    <p className='custom-subtitle'>{translation.delete_account_subtitle}</p>

                    <ol className='custom-instructions'>
                        <li>{translation.delete_account_step_1}</li>
                        <li>{translation.delete_account_step_2}</li>
                        <li>{translation.delete_account_step_3}</li>
                    </ol>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(DeleteAccountInstructions);
