import React from "react";
import getAxiosConfig from "../utils/axiosConfig";
import axios from "axios";
import {toast} from 'react-toastify';
import { IoCheckmark, IoCheckmarkDoneSharp } from "react-icons/io5";
import {connect} from "react-redux";

const EnrollmentsTable = ({ enrollments , isOwner, refreshEvent, translation}) => {

  const onApprove = async (eventId, enrollmentId) => {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v2/event/enrolments/${eventId}/approve/`,
            { "ids": [enrollmentId] },
            getAxiosConfig()
        );
        toast.success(`Approved enrollment`);
        refreshEvent();
    } catch (error) {
        toast.error(error.response.data.detail);
    }
  };

  const onReject = async (eventId, enrollmentId) => {
    try {
      const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/v2/event/enrolments/${eventId}/disapprove/`,
          { "ids": [enrollmentId] },
          getAxiosConfig()
      );
      toast.success(`Rejected enrollment`);
      refreshEvent();
    } catch (error) {
        toast.error(error.response.data.detail);
    }
  };

  const renderRow = (enrollment, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <img src={enrollment.user_photo} className="rounded-circle club-members-img" alt={`${enrollment.user_full_name}`} />
      </td>
      <td>{enrollment.user_full_name}</td>
      {isOwner && <td className="d-none d-md-table-cell">{enrollment.user_phone}</td>}
      <td className="">{enrollment.enrollment_status === 'approved' ? <IoCheckmarkDoneSharp className='color-green' /> : <IoCheckmark className="color-galben" />}</td>

        {isOwner &&
        <td className="">
          {enrollment.enrollment_status !== 'approved' && (
            <button className="btn btn-light-blue me-3"
                onClick={() => onApprove(enrollment.event, enrollment.id)}>
              <small>Approve</small>
            </button>)
          }
          {enrollment.enrollment_status !== 'rejected' && (
            <button className="btn btn-light-red"
                  onClick={() => onReject(enrollment.event, enrollment.id)}>
              <small>Reject</small>
            </button>)
          }
          
          
        </td>
      }
    </tr>
  );

  return (
    <div className="club-members-table-container">
      {/*<div className="club-members-header">{translation.enrollments}</div>*/}
        <table className="table table-hover w-100 mt-3">
            <thead>
              <tr>
                  <th className="galben text-white">#</th>
                  <th className="galben text-white"></th>
                  <th className="galben text-white">{translation.enroll_name}</th>
                  {isOwner && <th className="galben text-white d-none d-md-table-cell">{translation.enroll_contact}</th>}
                  <th className="galben text-white">Status</th>
                  {isOwner && <th className="galben text-white"></th>}
                  
              </tr>
            </thead>
            <tbody>
            {enrollments && enrollments.results ? enrollments.results.map(renderRow) : null}
            </tbody>
        </table>
    </div>
  );
};

const mapStateToProps = state => ({
    translation: state.appState.translation,
});
export default connect(mapStateToProps, null)(EnrollmentsTable);