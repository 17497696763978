import axios from 'axios';
import  getAxiosConfig  from '../utils/axiosConfig';
import { toast } from 'react-toastify';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAIL = 'ACCEPT_INVITATION_FAIL';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAIL = 'SEND_INVITATION_FAIL';
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS';
export const UPDATE_ROLES_FAIL = 'UPDATE_ROLES_FAIL';

export const acceptInvitation = (membershipId) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/club/club-membership/${membershipId}/accept/`,
            {},
            getAxiosConfig()
        );

        toast.success('You have accepted club invitation. You can now go to Club page.');
    } catch (error) {
        toast.error(error?.response?.data?.message || 'Couldn\'t accept invitation.');
    }
};

// Action: Send Club Invitation
export const sendClubInvitation = (clubId, email) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/club/club-membership/${clubId}/invite/`,
            { email },
            getAxiosConfig()
        );
        dispatch({
            type: SEND_INVITATION_SUCCESS,
            payload: response.data
        });
        return Promise.resolve('Invitation sent successfully!');
    } catch (error) {
        let errorMessage = 'Error sending invitation';
        if (error.response && error.response.data) {
            errorMessage = error.response.data.message || errorMessage;
        }
        dispatch({
            type: SEND_INVITATION_FAIL,
            payload: errorMessage
        });
        return Promise.reject(errorMessage);
    }
};


// Action: Update Roles
export const updateRoles = (updatedMembers) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v2/club/owner/members/bulk-edit/`,
            updatedMembers,
            getAxiosConfig()
        );

        dispatch({
            type: UPDATE_ROLES_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        let errorMessage = 'Error updating roles';
        if (error.response && error.response.data) {
            errorMessage = error.response.data.message || errorMessage;
        }

        dispatch({
            type: UPDATE_ROLES_FAIL,
            payload: errorMessage
        });
    }
};