import {
    LOAD_TRANSLATIONS,
    MENU_TOGGLE
} from './types';

export const load_translations = (language) => async dispatch => {
     dispatch({
        type: LOAD_TRANSLATIONS,
        payload: language
    });
};

export const menuToggle = () => async dispatch => {
    dispatch({
        type: MENU_TOGGLE,
    });
};