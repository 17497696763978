// src/components/EventStats.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import getAxiosConfig from '../utils/axiosConfig';
import Empty from '../components/Empty';
import { connect } from 'react-redux';

const EventStats = ({ eventId, translation, user }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!eventId) return;

    const fetchEventSummary = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v3/event/${eventId}/summary/`,
          getAxiosConfig()
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching event summary', error);
        toast.error(translation.fetch_error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventSummary();
  }, [eventId, translation]);

  if (loading) {
    return <div className="text-center mt-4">{translation.loading}</div>;
  }

  if (!data) {
    return <Empty label={translation.no_stats_available} />;
  }

  const getHourLabel = (hourNumber) => {
     return `${translation.hour} ${hourNumber}`;
  };

  return (
    <div className="container mt-4">
      <h2>{translation.event_statistics}</h2>

      {/* Basic Statistics */}
      <div className="mt-4">
        <h3 className="mt-4 mb-3">{translation.basic_statistics}</h3>
        <table className="table table-hover w-100 mt-3">
          <thead>
            <tr>
              <th className="galben text-white">{translation.statistic}</th>
              <th className="galben text-white">{translation.value}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="75">{translation.number_of_competitors}</td>
              <td>{data.number_of_competitors}</td>
            </tr>
            <tr>
              <td>{translation.total_catches}</td>
              <td>{data.total_catches}</td>
            </tr>
            <tr>
              <td>{translation.average_catches_per_competitor}</td>
              <td>{data.average_catches_per_competitor}</td>
            </tr>
            <tr>
              <td>{translation.average_catches_per_hour}</td>
              <td>{data.average_catches_per_hour}</td>
            </tr>
            <tr>
              <td>{translation.total_length_of_catches}</td>
              <td>{data.total_length_of_catches} cm</td>
            </tr>
            <tr>
              <td>{translation.average_length_of_catches}</td>
              <td>{data.average_length_of_catches} cm</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Species Statistics */}
      <div className="mt-4">
        <h3 className="mt-4 mb-3">{translation.species_statistics}</h3>
        <table className="table table-hover w-100 mt-3">
          <thead>
            <tr>
              <th className="galben text-white">{translation.statistic}</th>
              <th className="galben text-white">{translation.value}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{translation.number_of_scoreable_species}</td>
              <td>{data.number_of_scoreable_species}</td>
            </tr>
            <tr>
              <td>{translation.number_of_captured_species}</td>
              <td>{data.number_of_captured_species}</td>
            </tr>
            <tr>
              <td>{translation.captures_over_limit}</td>
              <td>{data.captures_over_limit}</td>
            </tr>
            <tr>
              <td>{translation.captures_under_limit}</td>
              <td>{data.captures_under_limit}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Hourly Captures */}
      <div className="mt-4">
        <h3 className="mt-4 mb-3">{translation.hourly_captures}</h3>
        <table className="table table-hover w-100 mt-3">
          <thead>
            <tr>
              <th className="galben text-white">{translation.hour}</th>
              <th className="galben text-white">{translation.count}</th>
              <th className="galben text-white">{translation.percentage}</th>
            </tr>
          </thead>
          <tbody>
            {data.hourly_captures.map((item, index) => (
              <tr key={index}>
                <td>{getHourLabel(item.hour)}</td>
                <td>{item.count}</td>
                <td>{item.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Captured Species Details */}
      <div className="mt-4">
        <h3 className="mt-4 mb-3">{translation.captured_species_details}</h3>
        <table className="table table-hover w-100 mt-3">
          <thead>
            <tr>
              <th className="galben text-white">{translation.species}</th>
              <th className="galben text-white">{translation.over_limit}</th>
              <th className="galben text-white">{translation.under_limit}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.captured_species_details).map(([species, counts]) => (
              <tr key={species}>
                <td>{species}</td>
                <td>{counts.over_limit}</td>
                <td>{counts.under_limit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  translation: state.appState.translation,
});

export default connect(mapStateToProps)(EventStats);
