import React from 'react';
import termsRO from './json/TermsAndConditions_ro.json';
import termsEN from './json/TermsAndConditions_en.json';
const TermsAndConditions = ({ language }) => {
  const terms = language === 'en' ? termsEN : termsRO;
  const sections = Object.values(terms.sections);

 return (
    <div>
      <h1>{terms.title}</h1>
      {sections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;