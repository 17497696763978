import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import TermsAndConditions from "../containers/TermsAndConditions";

const TermsModal = ({show, onHide}) => {
  const [termsLanguage, setTermsLanguage] = useState('en');

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button className='btn green text-white mt-3' onClick={() => setTermsLanguage('en')}>English</button>
        <button className='btn green text-white mt-3' onClick={() => setTermsLanguage('ro')}>Română</button>
        <TermsAndConditions language={termsLanguage} />
      </Modal.Body>
      <Modal.Footer>
        <button className='btn green text-white mt-3' onClick={onHide}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsModal;