import {
    LOAD_FISH_TYPES,
    LOAD_SPONSORS,
    LOAD_LOCATIONS,
    LOAD_EVENT_VALIDATORS,
    TEST_PHOTO_UPLOAD_SUCCESS,
    TEST_PHOTO_UPLOAD_FAIL,
    EVENT_ENROLL_SUCCESS,
    EVENT_ENROLL_FAIL,
    EVENT_ONGOING,
    EVENT_ONGOING_FAIL,
    LOAD_FISHING_SPOTS,
    LOAD_PERFORMANCE,
    LOAD_TOP_ANGLERS,
} from '../actions/types';

const initialState = {
    sponsors: null,
    fish_types: null,
    validators: null,
    utilsLoaded: false,
    locations: null,
    eventOngoing: null,
    eventOngoingFail: false,
    topAnglers: [],
    performance: null,
};

function utilsState(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_SPONSORS:
            return {
                ...state,
                sponsors: payload,
                utilsLoaded: true
            }
        case LOAD_FISH_TYPES:
            return {
                ...state,
                fish_types: payload,
                utilsLoaded: true
            }
        case LOAD_LOCATIONS:
            return {
                ...state,
                locations: payload,
                utilsLoaded: true
            }
        case LOAD_FISHING_SPOTS:
            return {
                ...state,
                fishingspots: payload,
                utilsLoaded: true,
            }
        case LOAD_EVENT_VALIDATORS:
            return {
                ...state,
                validators: payload,
                utilsLoaded: true
            }
        case TEST_PHOTO_UPLOAD_SUCCESS:
            return {
                ...state,
                status: 'success',
                message: payload.message
            }
        case TEST_PHOTO_UPLOAD_FAIL:
            return {
                ...state,
                status: 'error',
                message: payload.message
            }
        case EVENT_ENROLL_SUCCESS:
            return {
                ...state,
                status: 'success',
                message: payload.message
            }
        case EVENT_ENROLL_FAIL:
            return {
                ...state,
                status: 'error',
                message: payload.message
            }
        case EVENT_ONGOING:
            return {
                ...state,
                eventOngoing: payload,
                eventOngoingFail: false,
            }
        case EVENT_ONGOING_FAIL:
            return {
                ...state,
                eventOngoing: null,
                eventOngoingFail: true,
            }
        case LOAD_PERFORMANCE:
            return {
                ...state,
                performance: payload,
            }
        case LOAD_TOP_ANGLERS:
            return {
                ...state,
                topAnglers: payload,
            }
        default:
            return state
    }
}

export default utilsState;