import React, { useEffect } from 'react';

const Message = ({ message, clearMessage }) => {

    useEffect(() => {
        setTimeout(() => {
            clearMessage();
        }, 5000);
    }, []);

    return (
        <div className='notifications-container'>
            <div className='notification p-2 mb-2 green text-white'>
                {message}
            </div>
        </div>
    );
};

export default React.memo(Message);


