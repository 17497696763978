import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../actions/auth';

const Activate = ({ verify }) => {
    const [verified, setVerified] = useState(false);
    const navigate = useNavigate();
    const { uid, token } = useParams();

    useEffect(() => {
        if (verified) {
            navigate('/');
        }
    }, [verified, navigate]);

    const verify_account = useCallback(() => {
        if (uid && token) {
            verify(uid, token);
            setVerified(true);
        }
    }, [uid, token, setVerified, verify]);

    return (
        <div className='container'>
            <div
                className='d-flex flex-column justify-content-center align-items-center'
                style={{ marginTop: '200px' }}
            >
                <h5 className='mb-5 text-center'>Click the button below to finish activation.</h5>
                <button
                    onClick={verify_account}
                    type='button'
                    className='btn btn-light-green'
                >
                    Verify your account.
                </button>
            </div>
        </div>
    );
};

export default connect(null, { verify })(Activate);
