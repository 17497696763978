import React from 'react';
import DateTimePicker from 'react-datepicker';

const DatePicker = ({ name, value, onChange, label }) => {  
  return (
        <div className="mb-3">
          <label htmlFor="selectedDate" className="form-label w-100">
            { label }:
          </label>
          <DateTimePicker
            className="form-control"
            id={name}
            name={name}
            selected={value}
            onChange={onChange}
            showTimeSelect
          />
        </div>
    );
};

export default React.memo(DatePicker);


