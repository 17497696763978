import React, { useState, useEffect } from 'react';

let timeout;

const useClickOutside = (ref, preventClickRef) => {
    const [clickOutside, setClickOutside] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(preventClickRef.current === event.target) {
                return;
            }

            if (ref.current && !ref.current.contains(event.target)) {
                setClickOutside(true);
                
                timeout = setTimeout(() => {
                    setClickOutside(false);
                }, 0);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        // document.addEventListener("touchstart", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            // document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [ref, preventClickRef]);

    return clickOutside;
}

export default useClickOutside;