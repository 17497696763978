import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { loadMyClub } from '../actions/myClubState';
import Loader from '../components/Loader';
import ClubCard from "../components/ClubCard";
import Empty from '../components/Empty';

const MyClub = ({ clubMembers, clubMembersLoading, loadMyClub }) => {

    useEffect(() => {
        if (!clubMembers) {
            loadMyClub();
        }
    }, [loadMyClub, clubMembers]);
    
    return (
        <>
            {clubMembersLoading ? (
                <Loader />
            ) : (
                <div className={`page-container container-fluid`}>
                    <div className='row g-3 bg-white rounded'>
                        {
                            clubMembers
                                ? <ClubCard key={clubMembers.club_id} club={clubMembers} />
                                : <Empty label={`Nu faci parte din niciun club.`} />
                        }
                    </div>
                </div>
            )
            }
        </>
    );
};

const mapStateToProps = (state) => ({
  clubMembersLoading: state.myClubStateReducer.loading,
  clubMembers: state.myClubStateReducer.club,
});

const mapDispatchToProps = {
  loadMyClub,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyClub);