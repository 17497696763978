import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadMyCatchMapData } from '../actions/myCatchMapDataState';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Import marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import sadFish from './icons/sad_fish.png';

// Create a new icon instance with imported images
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

// Set the default icon for all Leaflet markers
L.Marker.prototype.options.icon = DefaultIcon;


// Define custom icon
const customIcon = new L.Icon({
    iconUrl: require('./icons/map_marker_icon.png'),
    iconRetinaUrl: require('./icons/map_marker_icon.png'), // Optional for retina display icons
    iconSize: [35, 35], // Size of the icon
    iconAnchor: [12, 35],
    popupAnchor: [1, -44],
    // shadowUrl: require('./icons/map_marker_icon.png'), // Optional shadow image
    shadowSize: [41, 41] // Size of the shadow image
});


const MyEventCatchMap = ({ eventId }) => {
  const dispatch = useDispatch();

  const mapData = useSelector(state => state.myMapCatchDataReducer.mapdata);
  const error = useSelector(state => state.myMapCatchDataReducer.error);
  const [mapDataFormated, setMapDataFormated] = useState(null);

  useEffect(() => {
    dispatch(loadMyCatchMapData(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if(!mapData) return;
    const validData = mapData.filter(point => (
      point &&
      parseFloat(point.gps_latitude) !== 0 &&
      parseFloat(point.gps_longitude) !== 0
    ))
    setMapDataFormated(validData);
  }, [mapData]);

  const getCenter = (data) => {
    let lat = 0;
    let lng = 0;
    data.forEach((el) => {
      lat += parseFloat(el.gps_latitude);
      lng += parseFloat(el.gps_longitude);
    });
    
    return [lat / data.length, lng / data.length];
  }


  // Render the map, an error message, a no-data message with an image, or a loading message

  return (
    mapDataFormated && mapDataFormated.length>0 && (<MapContainer center={getCenter(mapDataFormated)} zoom={12} scrollWheelZoom={false} style={{ height: "50vh", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {mapDataFormated.map((point, index) => {
          const lat = parseFloat(point.gps_latitude);
          const lng = parseFloat(point.gps_longitude);
          return (
            <Marker
              key={index}
              position={[lat, lng]}
              icon={customIcon}>
              <Popup>
                {point.fish_name} [{point.fish_length}cm] <br/>
                {point.full_name}<br/>
                <img src={point.photo_url} alt="Event" style={{ width: '100px', height: 'auto' }} />
              </Popup>
            </Marker>
          );
        })
      }
    </MapContainer>)
  );
};


const mapStateToProps = state => ({
    selectedEvent: state.eventsState.selectedEvent,
});
const mapDispatchToProps = () => {

}


export default MyEventCatchMap;
