export const getBarer = () => {
    const bearer = localStorage.getItem('access');

    return bearer;
}

export const getRefresh = () => {
    const refresh = localStorage.getItem('refresh');

    return refresh;
}
