import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const ResetPassword = ({ reset_password }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({ email: '' });
    const [captchaValue, setCaptchaValue] = useState(null);

    const navigate = useNavigate();
    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        if (captchaValue) {
            reset_password(email, captchaValue);
            setRequestSent(true);
        } else {
            toast.error('Please verify that you are a human!');
        }
    };

    if (requestSent) {
        navigate('/');
        return null;
    }

    return (
        <>
            <div className='login-container mt-4 d-flex justify-content-center'>
                <div className='login-section d-flex rounded justify-content-center flex-column px-4'>
                    <div className='d-flex w-100 justify-content-center'>
                        <img className='img-responsive w-100' src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png' alt='logo' />
                    </div>

                    <h1 className='custom-title'>Password Reset</h1>
                    <form onSubmit={onSubmit}>
                        <div className='form-group'>
                            <input
                                className='login-input w-100 mt-2 py-2'
                                type='email'
                                placeholder='Email'
                                name='email'
                                value={email}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={setCaptchaValue}
                            className='d-flex justify-content-center my-3'
                        />
                        <button className='btn green text-white w-100 py-2' type='submit'>Reset Password</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default connect(null, { reset_password })(ResetPassword);
